import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import MobileNavTop from "../Header/MobileTopNav";
import BottomFooter from "../Footer/BottomFooter";
import { s3url, apiUrl } from "../../helper/endpoint";

import { Container } from "react-bootstrap";

import { Row, Col, Layout, Carousel, Card } from "antd";
import { sendLocationAction } from "../../store/actions/locationaction";

import { rootAction } from "../../store/slices/rootSlices";

import salonImg from "../../assets/webBanner/SALON1.png";
import serviceImg from "../../assets/webBanner/SERVICE.png";
import restaurantImg from "../../assets/webBanner/RESTAURANT.png";
import eMarketImg from "../../assets/webBanner/E-mart.png";
import doll from "../../assets/ezygo.shop.png";
import slide1 from "../../assets/mobile/coins/slide-1.webp";
import slide2 from "../../assets/mobile/coins/slide-2.webp";
import slide3 from "../../assets/mobile/coins/slide-3.webp";
import slide4 from "../../assets/mobile/coins/slide-4.webp";
import slide5 from "../../assets/mobile/coins/slide-5.webp";
import { customerAction } from "../../store/slices/customerslices";
import { restaurantAction } from "../../store/slices/restaurantslices";
import { cartAction } from "../../store/slices/cartslices";
import { productActions } from "../../store/slices/productslices";
// Import for Language translater
import { useTranslation } from "react-i18next";


function App() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  // For Translation
  const { t, i18n } = useTranslation(); // Hook to access translations
  const [visible, setVisible] = useState(false);
  // End For Translation
  const { Meta } = Card;
  const carouselRef = useRef(null);
  let user = useSelector((state) => state.userReducer);
  const location = useSelector((state) => state.locationReducer);
  useEffect(() => {
    dispatch(sendLocationAction());
  }, [dispatch]);
  const [giftCardModal, setgiftCardModal] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);
  const renderAd = (data) => {
    let adType = data.contentType == "video" ? "video" : "picture";
    let url = `${s3url}/${data.url}`;

    let ele = (
      <img
        src={url}
        alt={adType}
        height={240}
        className=" border-radius-10"
        loading="lazy"
      />
    );

    if (adType == "video") {
      ele = (
        <video
          controls
          loop
          autoPlay
          muted
          width="100%"
          height="200"
          onEnded={handleVideoEnd}
          className="border-radius-10"
        >
          <source src={url} type="video/mp4" />
          {t("OnlineBrowser")}
        </video>
      );
    }
    return ele;
  };
  const handleVideoEnd = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const getHomePageAdv = async () => {
    // console.log("---fetching advertisments for home page---");
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/treeDrive/restaurant/advertisementsNew`
      );

      let homePageAds = response.data.data;
      setAdvertisements(homePageAds);
      // console.log("--homepage advertisements---", homePageAds);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const clearStorageAndState = () => {
    dispatch(customerAction.removeCustomer());
      dispatch(restaurantAction.removeRestaurants());
      dispatch(cartAction.emptyCart());
      dispatch(productActions.clearSuperCategory());

      // Items to remove from localStorage and sessionStorage
      const storageKeys = [
        "hasSeenPopUp",
        "resProduct",
        "restaurantSlug",
        "restaurantName",
        "TFLCartData",
        "totalToPayTFL",
        "deliveryChargesTFL",
        "resName",
        "resId",
        "resLoc",
        "restaurantWebsite"
      ];

      storageKeys.forEach((key) => {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
      });
    };

    clearStorageAndState();
    if (isMobile) {
      getHomePageAdv();
    }
  }, []);

  const handleClick = (modalId) => {
    // console.log("modalId", modalId);
    navigate(`/modal/${modalId}`);
  };

  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const BannerItem = ({ onClick, src, alt }) => (
    <a onClick={onClick} style={{ textDecoration: "none" }}>
      <img src={src} alt={alt} width={"100%"} loading="lazy" />
    </a>
  );
  return (
    <Layout>
      {!isMobile && (
        <>
          {" "}
          <Row className="bannerImg">
            {/* Left Column */}
            <Col span={6} className="d-flex justify-content-center">
              <Row className="px-3 py-2" gutter={[0, 16]}>
                <Col span={12} style={{ paddingRight: "8px" }}>
                  <Row className="d-flex flex-column" gutter={[0, 16]}>
                    <Col
                      span={24}
                      className="image-container border-radius-10"
                      style={{ background: "#f6ac51" }}
                    >
                      <a
                        onClick={() => {
                          navigate("/salonslist");
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <p className="font-18 font-700 mb-0 pt-3 text-left ps-4">
                          {t("InviteSalon")}
                          {/* Salong */}
                        </p>
                        <img src={salonImg} className="width-100 height-100" />
                      </a>
                    </Col>
                    <Col
                      span={24}
                      className="image-container border-radius-10"
                      style={{ background: "#f4c500 " }}
                    >
                      <a
                        onClick={() => {
                          navigate("/digitalServices");
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <p className="font-18 font-700 mb-0 pt-3 text-left ps-4">
                          {t("OnlineService")}
                        </p>
                        <img
                          src={serviceImg}
                          className="width-100 height-100"
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col span={12} style={{ paddingLeft: "8px" }}>
                  <Row className="d-flex flex-column" gutter={[0, 16]}>
                    <Col
                      span={24}
                      className="image-container border-radius-10"
                      style={{ background: "#79d8ce" }}
                    >
                      <a
                        onClick={() => {
                          dispatch(rootAction.setOrderType("Drive Out"));
                          navigate("/restaurants");
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <p className="font-18 font-700 mb-0 pt-3 text-left ps-4">
                          {/* {t("OnlineFood")} */}
                          {t("RestaurantsWord")}
                        </p>
                        <img
                          src={restaurantImg}
                          className="width-100 height-100"
                        />
                      </a>
                    </Col>
                    <Col
                      span={24}
                      className="image-container border-radius-10"
                      style={{ background: "#0478e9 " }}
                    >
                      <a
                        onClick={() => {
                          navigate("/tflMarket");
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <p className="font-18 font-700 mb-0 pt-3 text-left ps-4 text-white">
                          {t("OnlineMarket")}
                        </p>
                        <img
                          src={eMarketImg}
                          className="width-100 height-100"
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* Right Column */}
            <Col span={11}>
              <img src={doll} alt="Banner" className="width-100 height-100" />
            </Col>
          </Row>
        </>
      )}
      {isMobile && <MobileNavTop />}
      {isMobile && (
        <Container
          fluid
          id="online-header"
          className="px-0 py-5 my-2 mobileView"
        >
          <Row justify="center" align="middle" className="px-3 pt-3">
            <Col span={24}>
              {user.token !== "" && (
                <p className="pl-1 pt-1 mb-0">
                  <>
                    {t("OnlineHello")}{" "}
                    <b>
                      {capitalizeFirstLetter(user.firstName)}{" "}
                      {capitalizeFirstLetter(user.lastName)}!👋
                    </b>
                  </>
                </p>
              )}
              <h3 className="welcome-text mb-0 sansita-black">
                {t("WelcomeTitle")}
              </h3>
              <p className="pl-1 pt-1">
                {user.token === "" && (
                  <>
                    {t("OnlineBenefits")}
                    <NavLink to="/signup">
                      <i>
                        <u>
                          <b>{t("ResetRegister")}</b>
                        </u>
                      </i>
                    </NavLink>{" "}
                    {t("OnlineYou")}
                    <NavLink to="/login">
                      <i>
                        <u>
                          <b>{t("FormLogin")}</b>{" "}
                        </u>
                      </i>
                    </NavLink>{" "}
                    !!
                  </>
                )}
              </p>
            </Col>
          </Row>

          <div className="px-3 mb-2">
            <Carousel
              id="topSlider"
              autoplay
              className="border-radius-10"
              style={{
                backgroundImage: `url(${require("../../assets/mobile/coins/bg.webp")})`,
                backgroundSize: "cover"
              }}
            >
              <div
                key="0"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(1)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  {t("ModalActivateTitle")}
                </h3>
                <img
                  src={slide1}
                  alt="TFL Coins"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
              <div
                key="1"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(2)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  {t("ModalAgentWork")}
                </h3>
                <img
                  src={slide2}
                  alt="EzyGo AI"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
              <div
                key="2"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(3)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  {t("OnlineEarnCoins")}
                </h3>
                <img
                  src={slide3}
                  alt="Fill Bag with Coins"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
              <div
                key="3"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(4)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  {t("ModalBuyGift")}
                </h3>
                <img
                  src={slide4}
                  alt="Discover More"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
              <div
                key="3"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(5)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  {t("ModalHold")}
                </h3>
                <img
                  src={slide5}
                  alt="Discover More"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
            </Carousel>
          </div>

          <Row className="px-3 py-2" gutter={[0, 16]}>
            <Col span={12} style={{ paddingRight: "8px" }}>
              <Row className="d-flex flex-column" gutter={[0, 16]}>
                <Col
                  span={24}
                  className="image-container border-radius-10"
                  style={{ background: "#f6ac51" }}
                >
                  <a
                    onClick={() => {
                      navigate("/salonslist");
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="font-18 font-700 mb-0 pt-3 text-left ps-4">
                      {t("InviteSalon")}
                    </p>
                    <img src={salonImg} width={"100%"} />
                  </a>
                </Col>
                <Col
                  span={24}
                  className="image-container border-radius-10"
                  style={{ background: "#f4c500 " }}
                >
                  <a
                    onClick={() => {
                      navigate("/digitalServices");
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="font-18 font-700 mb-0 pt-3 text-left ps-4">
                      {t("OnlineService")}
                    </p>
                    <img src={serviceImg} width={"100%"} />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col span={12} style={{ paddingLeft: "8px" }}>
              <Row className="d-flex flex-column" gutter={[0, 16]}>
                <Col
                  span={24}
                  className="image-container border-radius-10"
                  style={{ background: "#79d8ce" }}
                >
                  <a
                    onClick={() => {
                      dispatch(rootAction.setOrderType("Drive Out"));
                      navigate("/restaurants");
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="font-18 font-700 mb-0 pt-3 text-left ps-4">
                      {/* {t("OnlineFood")} */}
                      {t("RestaurantsWord")}
                    </p>
                    <img src={restaurantImg} width={"100%"} />
                  </a>
                </Col>
                <Col
                  span={24}
                  className="image-container border-radius-10"
                  style={{ background: "#0478e9 " }}
                >
                  <a
                    onClick={() => {
                      navigate("/tflMarket");
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <p className="font-18 font-700 mb-0 pt-3 text-left ps-4 text-white">
                      {t("OnlineMarket")}
                    </p>
                    <img src={eMarketImg} width={"100%"} />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          {advertisements.length > 0 ? (
            <div className="px-3 pb-2 border-radius-10">
              <Carousel
                effect="fade"
                autoplay={true}
                autoplaySpeed={5000}
                ref={carouselRef}
                dots={false}
                className="mt-4 border-radius-10"
              >
                {advertisements.map((data, index) => {
                  return (
                    <div key={index} className=" border-radius-10">
                      {renderAd(data)}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            ""
          )}
          <Row className="px-3 bottomThought pb-5">
            <Col span={24} className="mt-3 sansita-black">
              <p className="pb-5">{t("OnlineDiscover")}</p>
            </Col>
          </Row>
        </Container>
      )}
      <BottomFooter />
    </Layout>
  );
}

export default App;
