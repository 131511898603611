import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Checkbox,
  Input,
  Divider,
  Popover
} from "antd";
import "../../../../../topbar.css";
import { cartAction } from "../../../../../../../store/slices/cartslices";
import ExtraProducts from "../../../../ExtraProducts/ExtraProducts";
import blackCoin from "../../../../../../../assets/mobile/blackCoin.webp";
import { s3url } from "../../../../../../../helper/endpoint";
import { useDispatch, useSelector } from "react-redux";
import getDiscount from "../../../../../../../helper/discountHelper";

// Import for Language translater
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const ConfirmProductModal = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const [isModalVisible, setIsModalVisible] = useState(true);
  const [superCategoryState, setsuperCategory] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [ingOptions, setingOptions] = useState([]);
  const [instruction, setInstruction] = useState([]);
  const [instructionArray, setInstructionArray] = useState([]);
  const [instructionIds, setInstructionIds] = useState([]);
  const [onMildChecked, setOnMildCheck] = useState(false);
  const [onSpicyChecked, setonSpicyChecked] = useState(false);
  const [onExtraSpicyChecked, setonExtraSpicyChecked] = useState(false);
  const [extraItems, setExtraItems] = useState([]);
  const [extraInstructions, setextraInstructions] = useState("");
  const [price, setprice] = useState(0);
  const [extraPrice, setExtraPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  let [alertMsg, setAlertMsg] = useState("");

  const [err_msg, seterr_msg] = useState("");

  const [disable, setDisable] = React.useState(false);

  const [ing, setIng] = useState([]);
  let superCategory = useSelector(
    (state) => state.productReducer.superCategory
  );
  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );

  let res = useSelector((state) => state.restaurantReducer.selectedRestaurant);
  let root = useSelector((state) => state.rootReducer);

  let cart = useSelector((state) => state.cartReducer);
  let dispatch = useDispatch();
  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    if (superCategory.data && superCategory.data.length > 0) {
      console.log("superCategory.products", superCategory.data);
      console.log(
        "Filtered superCategory:",
        superCategory.data.filter(
          (sin) => sin.isShowAsExtra && sin.products && sin.products.length > 0
        )
      );

      setsuperCategory(
        superCategory.data.filter((sin) => {
          console.log("sin superCategory.filter", sin.products);
          return sin.isShowAsExtra && sin.products && sin.products.length > 0;
        })
      );
    }
  }, [superCategory]);
  const handleOk = () => {
    setIsModalVisible(false);

    props.closeModal();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    props.closeModal();
  };
  const onChange = (event, sin) => {
    if (event.target.checked) {
      setInstructionIds((stateIns) => {
        return stateIns.filter((stateIns) => {
          return stateIns != sin.id;
        });
      });
      setInstructionArray((stateIns) => {
        return stateIns.filter((stateIns) => {
          return stateIns != `Uten ${sin.label}`;
        });
      });
    } else {
      setInstructionArray((insState) => {
        return [...insState, `Uten ${sin.label}`];
      });
      setInstructionIds((insState) => {
        return [...insState, sin.id];
      });
    }
  };

  let onChangeExtra = (event, type) => {
    if (event.target.checked) {
      if (type == "Mild") {
        setextraInstructions(type);
        setonExtraSpicyChecked(false);
        setonSpicyChecked(false);
        setOnMildCheck(true);
      }
      if (type == "Strong") {
        setextraInstructions(type);
        setOnMildCheck(false);
        setonSpicyChecked(false);
        setonExtraSpicyChecked(true);
      }
      if (type == "Medium") {
        setextraInstructions(type);
        setOnMildCheck(false);
        setonSpicyChecked(true);
        setonExtraSpicyChecked(false);
      }
    } else {
      setonExtraSpicyChecked(false);
      setonSpicyChecked(false);
      setOnMildCheck(false);
      setextraInstructions("");
    }
  };

  const onAddExtraItem = (checked, extraItem) => {
    let { image, name, price, _id, ingredents, incPrice } = extraItem;
    let singleItem = {
      image,
      name,
      price,
      _id,
      ingredents,
      incPrice
    };
    if (checked) {
      setExtraItems((sin) => {
        return [...sin, singleItem];
      });

      setprice((price) => price + Number(singleItem.incPrice));
      setExtraPrice((price) => price + Number(singleItem.incPrice));
    } else {
      setExtraItems((sin) => {
        let arr = sin.filter((singF) => {
          return singF._id != singleItem._id;
        });
        return arr;
      });
      setprice((price) => price - Number(singleItem.incPrice));
      setExtraPrice((price) => price - Number(singleItem.incPrice));
    }
  };
  useEffect(() => {
    let options = props.ing.map((sinIng) => {
      return { label: sinIng.name, value: sinIng.name, id: sinIng.ingId };
    });
    setIng(options);
    setprice(+props.singleProduct.incPrice);
    setingOptions(options);
  }, [props.ing, props.singleProduct.incPrice]);

  useEffect(() => {}, [instructionArray]);

  useEffect(() => {
    let { discountCalculated, discountPrice } = getDiscount(
      cart.totalPrice + price,
      restaurant,
      root.orderType
    );

    setDiscountPercent(discountCalculated);
    setDiscountPrice(discountPrice);
  }, [cart, restaurant, price]);
  const onAddToCartHandler = () => {
    let ItemData = {
      ...props.singleProduct,
      extraItems: extraItems,
      extraPrice: extraPrice,
      instruction: instructionArray,
      extraInstructions: extraInstructions,
      totalPrice: price,
      quantity: quantity,
      discountCalculated: discountPercent,
      discountPrice: discountPrice,
      instructionIds: instructionIds
    };
    dispatch(cartAction.addToCart(ItemData));
    if (props.isMobile) {
      props.onCloseMobileProductModal();
    }
    handleOk();
  };
  useEffect(() => {}, [extraItems]);

  let onInstructionChange = (event) => {
    setInstructionArray((insState) => event.target.value.split(","));
  };
  const add_quantity_price = (add) => {
    if (add) {
      if (quantity < 50) {
        setQuantity((quantity) => {
          return quantity >= 50 ? 50 : quantity + 1;
        });
        setprice(
          (price) => Number(price) + Number(props.singleProduct.incPrice)
        );
      } else {
        seterr_msg(t("Max50Allowed"));
      }
    } else {
      if (quantity > 1) {
        setQuantity((quantity) => {
          return quantity <= 1 ? 1 : quantity - 1;
        });

        setprice((price) => price - props.singleProduct.incPrice);

        seterr_msg((err_msg) => {
          if (quantity < 50) {
            err_msg = "";
          }
        });
      }
    }
  };
  let productName = () => {
    return props.isMobile
      ? props.singleProduct.name
      : props.singleProduct.name + " Kr." + props.singleProduct.incPrice;
  };

  const [openDesc, setOpenDesc] = useState(false);
  const hideDesc = () => {
    setOpenDesc(false);
  };
  const handleOpenChange = (newOpenDesc) => {
    setOpenDesc(newOpenDesc);
  };
  return (
    <Modal
      className="commonShadowAndRound productDisplay-card"
      title={
        <span className="custom-modal-title font-16">{productName()}</span>
      }
      // title={productName()}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      footer={
        true ? (
          <Row className="d-flex justify-content-between  py-1">
            <Col span={10} className="m-0 d-flex blackCoin align-items-en">
              <img alt="blackCoin" src={blackCoin} width="25px" height="25px" />
              &nbsp;<h4 className="mb-0"> Kr. {price.toFixed(2)}</h4>
            </Col>
            <Col span={10} className="m-0">
              <Button
                type="primary"
                block
                className="checkoutBtn border-radius-5"
                onClick={onAddToCartHandler}
              >
                {t("AddButton")}
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )
      }
    >
      <Row className="justify-content-between commonShadowAndRound py-3 px-4 productModal">
        <Col
          span={8}
          className=" d-flex flex-column justify-content-center align-items-center"
        >
          <img
            alt={props.singleProduct.name}
            src={`${s3url}/${props.singleProduct.thumbnail}`}
            className="width-100 height-100 border-radius-10"
          />
        </Col>
        <Col span={14} className="flex-column">
          <h3 className="font-16">{props.singleProduct.name}</h3>
          <div className="d-flex">
            <p className="productName mb-0 font-16">
              ({"Kr. " + props.singleProduct.incPrice})
            </p>{" "}
            &nbsp; &nbsp; &nbsp;
            {props.singleProduct.showOffer && (
              <p
                className="mb-0 d-flex align-items-center"
                style={{
                  textDecorationLine: "line-through"
                }}
              >
                ({"Kr. " + props.singleProduct.price})
              </p>
            )}
          </div>
          {props.singleProduct.ingredentsDesc != "" ? (
            <p className="mt-1">
              {props.isMobile && (
                <Popover
                  content={
                    <>
                      <p>{props.singleProduct.ingredentsDesc}</p>
                      <a onClick={hideDesc}>{t("CloseButton")}</a>
                    </>
                  }
                  title="Beskrivelse"
                  trigger="click"
                  open={openDesc}
                  onOpenChange={handleOpenChange}
                >
                  <Button
                    className="p-0 text-black bgColorWhite seeDescButton"
                    style={{ border: "none" }}
                  >
                    <u>{t("SeeDescription")}</u>
                  </Button>
                </Popover>
              )}
            </p>
          ) : (
            ""
          )}
          <div className="d-flex mt-1">
            <Row className="increaseBtnBOrders" justify="center" align="middle">
              <Col span={6}>
                <Button
                  type="text"
                  onClick={() => {
                    add_quantity_price(false);
                  }}
                >
                  -
                </Button>
              </Col>
              <Col span={12}>{quantity}</Col>
              <Col span={6}>
                <Button
                  type="text"
                  onClick={() => {
                    add_quantity_price(true);
                    setDisable(true);
                  }}
                >
                  +
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        {ingOptions == "" ? (
          ""
        ) : (
          <Col span={24} className="mt-2">
            <div>
              <p style={{ color: "#2F2F2F", fontWeight: "bold" }}>
                {t("ConfirmIngredients")}
              </p>
              {ingOptions.map((sin, index) => {
                return (
                  <div key={index}>
                    <Checkbox
                      onChange={(event) => onChange(event, sin)}
                      defaultChecked
                    >
                      {sin.value}
                    </Checkbox>
                  </div>
                );
              })}
            </div>
          </Col>
        )}
        {props.singleProduct.isExtraInformation && (
          <Col span={24} className="mt-2">
            <p
              style={{ color: "#2F2F2F", fontWeight: "bold" }}
              className="mb-1"
            >
              {t("Spiciness")}
            </p>
            <Row className="justify-content-between">
              <div>
                <Checkbox
                  onChange={(event) => onChangeExtra(event, "Mild")}
                  checked={onMildChecked}
                >
                  Mild
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  onChange={(event) => onChangeExtra(event, "Medium")}
                  checked={onSpicyChecked}
                >
                  Medium
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  onChange={(event) => onChangeExtra(event, "Strong")}
                  checked={onExtraSpicyChecked}
                >
                  Strong
                </Checkbox>
              </div>
            </Row>
          </Col>
        )}
      </Row>

      {console.log("superCategoryState in code", superCategoryState)}
      <Row className=" mt-2">
        <Divider plain>{t("Ekstrautstyr")}</Divider>
        <Col span={24} className="mt-2">
          <ExtraProducts
            onAddExtraItem={onAddExtraItem}
            superCategory={superCategoryState}
          />
        </Col>
      </Row>
      <Row className="pb-3 mt-2">
        <Col span={24}>
          <Divider plain>{t("AddComment")}</Divider>
          <TextArea
            className="w-100 spreadedShadow border-radius-10"
            placeholder=""
            rows={2}
            value={instructionArray.join(",")}
            onChange={onInstructionChange}
          />
        </Col>
      </Row>
      <Row className="p-2">
        <i>
          <p style={{ fontSize: "14px" }}>{err_msg}</p>
        </i>
      </Row>
    </Modal>
  );
};
export default ConfirmProductModal;
