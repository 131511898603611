import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Row, Col, Table, Radio, Card, Button, message } from "antd";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import axios from "axios";
import { CheckCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { saveCoupons } from "../../store/actions/couponAction";
import BottomFooter from "../Footer/BottomFooter";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
// Import for Language translater
import { useTranslation } from "react-i18next";

import { Trans } from "react-i18next";
import i18n from "./../../i18n";

function TflGiftCard(props) {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const user = useSelector((state) => state.userReducer);
  let navigate = useNavigate("");

  const [show, setShow] = useState(true);
  const [isDisabled, setisDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tflGiftCardAmt, setTflGiftCardAmt] = useState("");
  const [couponTotal, setCouponTotal] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { pathname } = useLocation();
  const [confirmCountryCode, setCountryCodeConfirm] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const dispatch = useDispatch();

  const coupanValueRef = useRef("");
  const countrycodeRef = useRef("");
  const phoneNumberRef = useRef("");

  const userId = user._id;

  const [errorObj, setErrorObj] = useState({
    emailError: false,
    couponError: false,
    phoneNumberError: false,
    confirmphoneNumberError: false,
    coupanValueError: false,
    conifirmEmailError: false
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const fetchGiftCardData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/treeDrive/tdlcoupon/TFLGiftRange`
        );
        setTflGiftCardAmt(response.data.data || []);
      } catch (error) {
        console.error("Error fetching Agent card data:", error);
      }
    };

    fetchGiftCardData();
  }, []);

  const handleSelect = (value) => {
    setSelectedOption(value);
  };

  const onSubmitCoupon = () => {
    let isFormVaild = true;
    setisDisabled(true);

    if (!selectedOption) {
      isFormVaild = false;
      setErrorObj((errState) => ({ ...errState, coupanValueError: true }));
    }

    if (coupanValueRef.current.value === "") {
      isFormVaild = false;
      setErrorObj((errState) => ({ ...errState, coupanValueError: true }));
    }

    if (isFormVaild) {
      dispatch(
        saveCoupons({
          recipientName: user.firstName || "",
          email: user.email || "",
          confirmEmail: user.email || "",
          phoneNumber: user.phone || "",
          confirmphoneNumber: user.phone || "",
          countryCode: user.countryCode || "",
          couponRangeId: selectedOption,
          customerId: userId || "",
          generatedBy: "EzyGoWeb"
        })
      )
        .then((response) => {
          setisDisabled(false);
          if (response.message) {
            message.warning(t("TFLLimitReached3") || response.message);
          } else {
            message.success(t("CouponSent"));
          }
        })
        .catch((error) => {
          setisDisabled(false);
          console.error("Error:", error);
        });
    }
  };
  const getCoupons = async () => {
    const cardHistoryData = {
      customerPhoneNo: user.phone,
      delStatus: "all"
    };

    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/getusercoupons`,
        cardHistoryData
      );
      setCouponTotal(response.data.data.totalCoupons);
    } catch (error) {
      console.error("Error fetching coupons:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
      setLoggedIn(false);
    }
    setLoggedIn(true);
  }, []);

  const couponDisplay = () => {
    if (couponTotal === 0) {
      return (
        <div className="d-flex flex-column align-item-center justify-content-center text-center mt-1">
          <p className="mb-0">{t("StartYourJourney")}</p>
        </div>
      );
    } else if (couponTotal === 1) {
      return (
        <div className="d-flex flex-column align-item-center justify-content-center text-center mt-1">
          <p className="mb-0">{t("CollectMoreCard")}</p>
        </div>
      );
    } else if (couponTotal === 2) {
      return (
        <div className="d-flex flex-column align-item-center justify-content-center text-center mt-1">
          <p className="mb-0">{t("YouHave2Card")}</p>
        </div>
      );
    } else if (couponTotal === 3) {
      return (
        <div className="d-flex flex-column align-item-center justify-content-center text-center mt-1">
          <p>{t("YouHave3Card")}</p>
        </div>
      );
    }
    //
  };
  useEffect(() => {
    getCoupons();
  }, []);
  const columns = [
    {
      title: t("TFLAgentCardTitle"),
      children: [
        {
          title: t("TFLGiftBuying"),
          dataIndex: "giftCardAmt",
          key: "giftCardAmt",
          render: (text) => `${text}`
        },
        {
          title: t("TFLGiftReceiving"),
          dataIndex: "finalAmount",
          key: "finalAmount",
          // render: (text) => `${text}`
          render: (text) => <strong>{text}</strong>
        }
      ]
    },

    {
      title: t("TFLGiftMonthly"),
      dataIndex: "monthlyPer",
      key: "monthlyPer",
      render: (text) => `${text}%`
    },
    {
      title: t("TFLGiftValidity"),
      dataIndex: "validity",
      key: "validity",
      render: (text) => `${text}`
    },
    {
      title: <CheckCircleOutlined />,
      key: "select",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Radio
          value={record._id}
          checked={selectedOption === record._id}
          onChange={() => handleSelect(record._id)}
        />
      )
    }
  ];
  const dataSource = Array.isArray(tflGiftCardAmt)
    ? tflGiftCardAmt.map((item) => ({
        ...item,
        key: item._id
      }))
    : [];
  return (
    <>
      {!isMobile && <PrivacyHeader />}
      <div
        className={
          isMobile
            ? "px-2 pt-2 pb-5 giftCardFormBg vh90andScroll"
            : "mission profilePages"
        }
      >
        <Row
          style={!isMobile && { marginTop: "90px" }}
          className={`${isMobile ? "mb-3" : "mb-2"} justify-content-center`}
        >
          <Col xs={24} lg={12} className="d-flex justify-content-between">
            <h3 className="font-24 font-700 mb-0">{t("TFLAgentCardTitle")}</h3>
            <LeftCircleOutlined
              onClick={() => window.history.back()}
              style={{ fontSize: "30px" }}
            />
          </Col>
        </Row>
        <Row className={`${!isMobile && "justify-content-center"} pb-4`}>
          <Col xs={24} lg={12}>
            <Card className="border-radius-10 width-100">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("TFLGiftAmount")}</Form.Label>

                  <Table
                    bordered
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ y: 620 }}
                    locale={{
                      emptyText: t("NoDataAvailable")
                    }}
                    pagination={false}
                    className="tflTable"
                  />
                  {errorObj.coupanValueError && (
                    <Form.Control.Feedback
                      className="custom danger"
                      type="invalid"
                    >
                      {t("SelectValidCardValue")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form>
              <div className="card-footer text-center">
                <Button
                  variant="primary"
                  onClick={onSubmitCoupon}
                  className="btn-primary border-radius-5 width-100"
                >
                  {t("TFLGiftBuyButton")}
                </Button>
                {couponDisplay()}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <BottomFooter />
    </>
  );
}

export default TflGiftCard;
