import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Layout,
  List,
  Row,
  Col,
  Space,
  Typography
} from "antd";
import {
  UserOutlined,
  FileTextOutlined,
  CreditCardOutlined,
  EyeOutlined,
  FileProtectOutlined,
  FileOutlined,
  LogoutOutlined,
  CopyrightOutlined,
  AlignLeftOutlined,
  TeamOutlined,
  LeftCircleOutlined,
  EditOutlined,
  CaretRightOutlined
} from "@ant-design/icons";
import { s3url } from "../../helper/endpoint";
import { userAction } from "../../store/slices/userslices";
import ProfileIcon from "../../assets/profileFallBack.webp";
// Import for Language translater
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import i18n from './../../i18n';

const { Text, Title } = Typography;

const ProfileScreen = () => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = useSelector((state) => state.userReducer);
  let userprofileImage = user.profileImage;
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const profileImageSrc =
    userprofileImage == "" ? ProfileIcon : `${s3url}/${userprofileImage}`;
  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());
    navigate("/login");
  };

  const profileSections = [
    {
      title: t("AboardArchive"),
      // "Arkiv",
      items: [
        {
          icon: <EditOutlined />,
          text: t("ShortCutEditProfile"),
          path: "/editProfile"
        },
        {
          icon: <CopyrightOutlined />,
          text: "TFL Coins",
          path: "/tflCoinExchange"
        },
        {
          icon: <UserOutlined />,
          text: t("ShortCutAgentProfile"),
          path: "/agentProfile"
        }
      ]
    },
    {
      title: t("ShortCutOrders"),
      items: [
        {
          icon: <AlignLeftOutlined />,
          text: t("ShortCutFoodOrder"),
          path: "/orderHistory"
        },
        {
          icon: <AlignLeftOutlined />,
          text: t("ShortCutOrderHistory"),
          path: "/orderHistory"
        }
      ]
    },
    {
      title: t("TFLAgentCardTitle"),
      items: [
        {
          icon: <CreditCardOutlined />,
          text: t("ShortCutBuy"),
          path: "/getTflCouponforme"
        },
        {
          icon: <TeamOutlined />,
          text: t("ShortCutBuyOthers"),
          path: "/getTflCouponforothers"
        },
        {
          icon: <EyeOutlined />,
          text: t("ShortCutSee"),
          path: "/showTflGiftCard"
        }
      ]
    },
    {
      title: t("ShortCutInformation"),
      items: [
        {
          icon: <FileProtectOutlined />,
          text: t("ShortCutPrivacy"),
          path: "/privacy"
        },
        {
          icon: <FileOutlined />,
          text: t("ShortCutTerms"),
          path: "/terms"
        }
      ]
    }
  ];
  return (
    <Layout className="shortCutStyle px-3 py-3">
      <div className="d-flex align-items-center justify-content-end">
        <LeftCircleOutlined
          onClick={() => navigate("/home")}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px"
          }}
        />
      </div>

      {user.firstName && (
        <Row className="d-flex justify-content-between">
          <Col span={6}>
            <Avatar
              size={100}
              src={profileImageSrc}
              style={{ marginBottom: "10px", border: "1px solid #999" }}
              alt={`${user.firstName}`}
            />
          </Col>
          <Col
            span={15}
            className="flex-column d-flex align-items-start justify-content-center"
          >
            <h4 className="font-18">
              {capitalizeFirstLetter(user.firstName)}{" "}
              {capitalizeFirstLetter(user.lastName)}!👋
            </h4>
            <p>{user.email}</p>
          </Col>
        </Row>
      )}

      {profileSections.map((section, index) => (
        <Card
          className="border-radius-5 spreadedShadow mt-3"
          key={index}
          style={{ marginBottom: "16px" }}
        >
          <Typography.Title level={5} style={{ marginBottom: "16px" }}>
            {section.title}
          </Typography.Title>
          <List
            dataSource={section.items}
            renderItem={(item) => (
              <List.Item className="d-flex">
                <NavLink
                  to={item.path}
                  style={{ textDecoration: "none" }}
                  className={
                    "d-flex justify-content-between width-100 text-black"
                  }
                >
                  <div className="d-flex align-items-center">
                    {" "}
                    {item.icon}
                    <Text>&nbsp;&nbsp;&nbsp;{item.text}</Text>
                  </div>
                  <CaretRightOutlined className="d-flex align-items-center" />
                </NavLink>
              </List.Item>
            )}
          />
        </Card>
      ))}
      <Button
        onClick={refreshAfterLogout}
        type="primary"
        className="width-100 text-center border-radius-5 mt-5 d-flex align-items-center justify-content-center"
        danger
        icon={<LogoutOutlined />}
        size="large"
        style={{ border: "none" }}
      >
        {t("ProfileLogoutButton")}
      </Button>
    </Layout>
  );
};

export default ProfileScreen;
