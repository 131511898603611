import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import logo from "../../assets/landscapelogo.webp";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import "./mobilenavbar.css";
// Import for Language translater
import { useTranslation } from 'react-i18next';

import { Trans } from "react-i18next";
import i18n from "./../../i18n";

import {
  revertCart,
  revertDis,
  revertLocation,
  revertProduct,
  revertRestaurant,
  revertRoot,
  revertCou
} from "../../store/actions/rootaction";

function MobileNavBar(props) {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  let dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname == "/home") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertLocation());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertRoot());
      dispatch(revertCou());
    }
  }, [location.pathname]);

  let navigate = useNavigate();
  let user = useSelector((state) => state.userReducer);

  return (
    <nav className="navbar p-0">
      <div className="nav-container">
        <NavLink to="/home" className="nav-logo col-1">
          <img
            src={logo}
            loading="lazy"
            alt="logo"
            className="logoSize"
            height={"60px"}
          />
        </NavLink>

        <div>
          <NavLink to="/home" className=" aboutNavLink col-1">
          Ezygo
          </NavLink>
          <NavLink to="/tflgiftcard" className=" aboutNavLink col-1">
            {t("NavAgent")}
          </NavLink>
          <NavLink to="/orderHistory" className=" aboutNavLink col-1">
            {t("NavHstory")}
          </NavLink>
          <NavLink to="/a-board" className=" aboutNavLink col-1">
            {t("NavArchive")}
          </NavLink>
          <NavLink to="/shareReferral" className=" aboutNavLink col-1">
            {t("NavShare")}
          </NavLink>
          <NavLink to="/contact" className=" aboutNavLink col-1">
            {t("NavContact")}
          </NavLink>
          {user.token != "" ? (
            ""
          ) : (
            <NavLink to="/login" className=" aboutNavLink col-1">
              {t("NavRegister")}
            </NavLink>
          )}
        </div>
      </div>
    </nav>
  );
}

export default MobileNavBar;
