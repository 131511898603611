import React, { useEffect, useState } from "react";
import MainDetail from "./Forms/FormMainDetail";
import MapForm from "./Forms/MapForm";
import { Card, Row, Col, Tag, Form } from "antd";
import Footer from "./Footer/footer";
import SideCart from "./SideCart/SideCart";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { saveCustomer } from "../../store/actions/customeraction";
import { isMobile } from "react-device-detect";
import BottomFooter from "../Footer/BottomFooter";
// Import for Language translater
import { useTranslation } from 'react-i18next';

const DriveOutCheckout = () => {
   //For Language Translater
    const { t, i18n } = useTranslation(); // Hook to access translations
  let [isformValid, setIsFormValid] = useState(false);
  let [enablePay, setEnablePay] = useState(false);
  let [formSet, setFormSet] = useState(false);
  let [userData, setUserData] = useState({});

  let [ifSlugIsThere, setIfSlugIsThere] = useState();
  let selfirstName = "";
  let sellastName = "";
  let selphoneNo = "";
  let selemail = "";
  let selcountryCode = "+47";
  let cartLength = useSelector((state) => state.cartReducer.cartItems.length);
  const dispatch = useDispatch();
  let location = useSelector((state) => state.locationReducer.locationAddress);
  let root = useSelector((state) => state.rootReducer);
  let navigate = useNavigate();

  const onAddCustomer = () => {
    dispatch(
      saveCustomer({
        email: userData.email,
        phone: userData.phoneNo,
        lastName: userData.lastName,
        firstName: userData.firstName,
        countryCode: userData.countryCode,
        locAddress: location
      })
    )
      .then(setEnablePay(true))
      .catch(setFormSet(true));
  };
  const setFormValid = (
    status,
    lastName,
    firstName,
    email,
    phoneNo,
    countryCode
  ) => {
    setUserData({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNo: phoneNo,
      countryCode: countryCode
    });

    selfirstName = firstName;
    sellastName = lastName;
    selemail = email;
    selphoneNo = phoneNo;
    selcountryCode = countryCode;
    setIsFormValid(status);
  };

  useEffect(() => {
    setIfSlugIsThere(localStorage.getItem("resName"));
    if (cartLength == 0) {
      navigate("/products", { replace: true });
    }
  }, []);
  let user = useSelector((state) => state.userReducer);
  let customer = useSelector((state) => state.customerReducer);

  const [form] = Form.useForm();
  let [firstName, setFirstName] = useState("");
  let [lastName, setlastName] = useState("");
  let [phoneNo, setPhoneNo] = useState("");
  let [countryCode, setCountryCode] = useState("47");
  let [email, setEmail] = useState("");

  //Set initial Form values
  useEffect(() => {
    if (user._id != "") {
      form.setFieldsValue({
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        phoneNo: user.phone
      });
      setFirstName(user.firstName);
      setlastName(user.lastName);
      setEmail(user.email);
      setPhoneNo(user.phone);
    }
    if (user._id == "" && customer._id != "") {
      form.setFieldsValue({
        firstname: customer.firstName,
        lastname: customer.lastName,
        email: customer.email,
        phoneNo: customer.phone
      });
      setFirstName(customer.firstName);
      setlastName(customer.lastName);
      setEmail(customer.email);
      setPhoneNo(customer.phone);
    }
  }, [user.firstName, user.lastName, user.email, user.phone]);

  useEffect(() => {
    if (formSet) {
      setFirstName("");
      setlastName("");
      setEmail("");
    }
  }, [formSet]);

  return (
    <>
      <div className="formWrapper" id="checkout">
        <Row className="checkoutRow justify-content-evenly width-100">
          {!enablePay ? (
            <Col xs={24} sm={8}>
              <Card bordered={false} className="cardContainer">
                <Row className="justify-content-between formHeader">
                  <Col>
                    {t("CustomerDetails")}
                  </Col>
                  <Col>
                    <Tag color="#000" className="border-radius-50 px-3">
                      {" "}
                      {root.orderType == "Take Away"
                        ? t("HeaderPickup")
                        : t("HeaderDriveOut")}
                      {/* {root.orderType == "Take Away" ? "Henting" : "Utkjøring"} */}
                    </Tag>
                  </Col>
                </Row>
                <MainDetail
                  className="p-0"
                  formSet={formSet}
                  setFormValid={setFormValid}
                  onAddCustomer={onAddCustomer}
                />

                {root.orderType == "Drive Out" && <MapForm />}
                <Footer
                  isEnablePay={enablePay}
                  formValid={isformValid}
                  onAddCustomer={onAddCustomer}
                />
              </Card>
            </Col>
          ) : (
            <Col xs={24} sm={8} className="productTotalContainer p-2">
              <SideCart isEnablePay={enablePay} />
            </Col>
          )}
        </Row>
      </div>

      {!ifSlugIsThere ? <>{isMobile && <BottomFooter />}</> : <></>}
    </>
  );
};
export default DriveOutCheckout;
