import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Card, Row, Col } from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { revertCou } from "../../store/actions/rootaction";
import { isMobile } from "react-device-detect";
import BottomFooter from "../Footer/BottomFooter";

// Import for Language translater
import { useTranslation } from "react-i18next";

import { Trans } from "react-i18next";
import i18n from "./../../i18n";

const SuccessCouponOrderSummary = () => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const dispatch = useDispatch();
  const { orderBYCoupon, tdcouponPaymentOtp } = useSelector(
    (state) => state.rootReducer
  );

  useEffect(() => {
    dispatch(revertCou());
  }, [dispatch]);

  const renderOrderStatus = () => {
    return orderBYCoupon ? (
      <div>
        <Row className="justify-content-center text-center">
          <div className="py-3 d-flex justify-content-center flex-column align-items-center">
            <AiOutlineCheckCircle className="icon-success font-55" />
            <h1 className="otp-text">OTP: {tdcouponPaymentOtp}</h1>
          </div>
          <div>
            <p>{t("SuccessThankYou")}</p>
            <p>{t("SuccessThankYouOrder")}</p>
          </div>
          <div className="p-5 goBackBtn">
            <NavLink to="/home" className="orderBtn">
              {t("SuccessReturnHome")}
            </NavLink>
          </div>
        </Row>
      </div>
    ) : (
      <Row className="text-center">
        <Col span={24}>
          <AiOutlineClose
            className="icon-failure font-55"
            style={{ color: "red" }}
          />
          <p className="fw-bold">{t("SuccessOrderPlaced")}</p>
          <div className="mt-3">
            <NavLink to="/home" className="orderBtn">
              {t("SuccessReturnHome")}
            </NavLink>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="vh90andScroll d-flex justify-content-center align-items-center">
        <section
          className={`p-2 d-flex align-items-center justify-content-center mission profilePages ${
            isMobile ? "mobile-view" : "desktop-view"
          }`}
        >
          <div className="formWrapper p-3">
            <Row className="formRow mt-5">
              <Col span={24}>
                <Card bordered={false} className="border-radius-10">
                  <div className="formLeft">{renderOrderStatus()}</div>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </div>
      <BottomFooter />
    </>
  );
};

export default SuccessCouponOrderSummary;
