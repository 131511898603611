import OrderNowButon from "../../../OrderNowButton";
import { s3url } from "../../../../../../helper/endpoint";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Row, Col } from "antd";
import {
  getIncreasedPrices,
  getProductsIngrident
} from "../../../Helper/producthelper";
// Import for Language translater
import { useTranslation } from "react-i18next";

const ProductComponent = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const { Text } = Typography;
  const cart = useSelector((state) => state.cartReducer);
  const [ing, setIng] = useState([]);
  const [price, setPrice] = useState(0);

  const [orignalPrice, setOrignalPrice] = useState(0);
  const [showOffer, setShowOffer] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    if (props.singleProduct) {
      let ingridents = getProductsIngrident(props.sinIng, props.singleProduct);
      setIng(ingridents);
    }
  }, [props.singleProduct, props.sinIng]);

  useEffect(() => {
    if (props.singleProduct && props.singleProduct.price) {
      let finalPrice = 0;
      let showOffer = false;

      let increasedPrice = getIncreasedPrices(
        props.singleProduct,
        props.incPricePer
      );

      finalPrice = increasedPrice;

      if (props.singleProduct.offerEnable) {
        let todayDate = new Date();
        let startDate = new Date(props.singleProduct.offer.startDate);
        let endDate = new Date(props.singleProduct.offer.endDate);

        if (todayDate >= startDate && todayDate <= endDate) {
          let prod = {};
          prod.price = props.singleProduct.offer.offerPrice;

          let offerPriceIncreased = getIncreasedPrices(prod, props.incPricePer);

          finalPrice = offerPriceIncreased;

          showOffer = true;
        }
      }

      setPrice(parseFloat(finalPrice).toFixed(1));
      setOrignalPrice(increasedPrice);
      setShowOffer(showOffer);
    }
  }, [props.singleProduct, props.incPricePer]);

  return (
    props.singleProduct && (
      <div>
        <Row>
          <Col span={24}>
            <Row className="justify-content-between p-4">
              <Col span={10}>
                {" "}
                <div className="single-img">
                  <img
                    alt="singleProduct photo"
                    src={`${s3url}/${props.singleProduct.thumbnail}`}
                    className="width-100 border-radius-5"
                  />
                </div>
              </Col>
              <Col span={13}>
                <div className="productDescription">
                  <div className="d-flex justify-content-between flex-column align-items-start">
                    <h3 className="productName">{`${props.singleProduct.name}`}</h3>{" "}
                    {props.singleProduct.offerEnable && showOffer ? (
                      <div className="d-flex justify-content-end">
                        <h5 className="productName ">
                          {` (Kr. ${Math.ceil(price)})`}
                        </h5>
                        <h5
                          className="productNamed-flex"
                          style={{
                            textDecorationLine: "line-through",
                            padding: "0px 20px"
                          }}
                        >
                          (Kr. {Math.ceil(orignalPrice)})
                        </h5>
                      </div>
                    ) : (
                      <div>
                        <h5 className="productName  mb-0 ">
                          (Kr. {Math.ceil(orignalPrice)})
                        </h5>
                      </div>
                    )}
                  </div>
                </div>
                {ing == "" ? (
                  ""
                ) : (
                  <>
                    <h6
                      className="mt-3 border-radius-5"
                      style={{
                        background: "#D9D9D9",
                        padding: "10px 10px 8px 20px"
                      }}
                    >
                      {t("ConfirmIngredients")}
                    </h6>
                    <ul>
                      <li>
                        {ing.map((sin) => {
                          return (
                            <span style={{ margin: "0 0.5rem" }} key={sin._id}>
                              {sin.name}
                            </span>
                          );
                        })}
                      </li>
                    </ul>
                  </>
                )}
              </Col>
              <Col span={24}>
                {props.singleProduct.ingredentsDesc == "" ? (
                  ""
                ) : (
                  <>
                    <h6
                      className="mt-3 border-radius-5"
                      style={{
                        background: "#D9D9D9",
                        padding: "10px 10px 8px 20px"
                      }}
                    >
                      {t("ProductDescription")}
                    </h6>
                    <p
                      style={{
                        height: "200px",
                        overflow: "hidden scroll"
                      }}
                    >
                      {props.singleProduct.ingredentsDesc
                        .split("\n")
                        .map((e) => {
                          return (
                            <span>
                              {e} <br></br>
                            </span>
                          );
                        })}
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="positionAbsolute width-100 bottom-0">
          <OrderNowButon
            isMobile={props.isMobile}
            className="btnWrap "
            handleCancelModal={props.handleCancelModal}
            ing={ing}
            singleProduct={{ ...props.singleProduct, incPrice: price }}
          />
        </Row>
      </div>
    )
  );
};
export default ProductComponent;
