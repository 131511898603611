import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Table,
  Button,
  Typography,
  Divider,
  Empty,
  Col,
  Row,
  message
} from "antd";
import { isMobile } from "react-device-detect";
import BottomFooter from "../Footer/BottomFooter";
import { LeftCircleOutlined } from "@ant-design/icons";
import potaliClosed from "../../assets/mobile/potali/close.png";
import potaliOpen from "../../assets/mobile/potali/open.png";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
// Import for Language translater
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export default function ToInviteList() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations
  const [loading, setLoading] = useState(false);
  const [showInvited, setShowInvited] = useState(false);
  const location = useLocation();
  const user = useSelector((state) => state.userReducer);
  let navigate = useNavigate();
  const userId = user._id;
  const address = useSelector((state) => state.locationReducer);

  const [invitationsList, setInvitationsList] = useState([]);
  const [restList, setRestList] = useState([]);

  const lng = address.longitude;
  const lat = address.latitude;

  const fetchInvitations = async () => {
    const payload = { appUserId: userId };

    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/invitationsList`,
        payload
      );
      const invitedData = response.data.data;

      // `restList` se sirf wahi items nikalna jo `invitedData` me match karte hain
      const filteredInvitedList = invitedData.filter((invite) =>
        restList.some((rest) => rest.properties.feature_name === invite.name)
      );

      setInvitationsList(filteredInvitedList);
    } catch (error) {
      console.error("Error fetching invitations list:", error);
    }
  };
  useEffect(() => {
    if (restList.length > 0) {
      fetchInvitations();
    }
  }, [restList]);

  useEffect(() => {
    const key = location.state?.key || "food_and_drink";
    console.log("Fetching data for key:", key);

    const fetchData = async () => {
      const apiUrl = `https://api.mapbox.com/search/v1/category/${key}?language=en&limit=100&proximity=${lng},${lat}&access_token=sk.eyJ1Ijoib20tZGF0YSIsImEiOiJjbG5tc3V0M3AwMzRhMmltcXFrdnp1dGpvIn0.gKxjTW2AVKe9P85bP8xaeQ`;

      try {
        const response = await axios.get(apiUrl);
        let resData = response.data.features;
        setRestList(resData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location.state?.key, lng, lat]);

  const isInvited = (name) => {
    console.log("invited", name);
    return invitationsList.some((invite) => invite.name === name);
  };

  const inviteFxn = async (name, address, cityName) => {
    const key = location.state?.key;
    const payload = {
      type: key === "salon" ? "salon" : "restaurant",
      name,
      appUserId: userId,
      latitude: lng,
      longitude: lat,
      cityName,
      address
    };

    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/invitations`,
        payload
      );

      if (response.data.status === 200) {
        message.success(t("InvitationSent"));
        fetchInvitations();
      } else if (response.data.status === 403) {
        message.error(t("AlreadyInvited") || response.data.message);
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      message.error(t("NotSendInvitation"));
    }
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  const data = showInvited
    ? invitationsList.map((invite, index) => ({
        key: index,
        name: invite.name,
        address: invite.address,
        city: invite.cityName,
        isInvited: true // Ye invited list hai to sab invited honge
      }))
    : restList
        .filter((data) => !isInvited(data.properties.feature_name)) // Sirf unko dikhaye jo invite nahi huye
        .map((data, index) => {
          const place = data.properties.context
            ? data.properties.context.find(
                (item) => item.localized_layer === "place"
              )
            : null;
          const placeName = place ? place.name : null;

          return {
            key: index,
            name: data.properties.feature_name,
            address: data.properties.place_name,
            city: placeName,
            isInvited: false
          };
        });

  const tableContent = () => {
    return (
      <>
        <Row className="justify-content-between">
          {" "}
          <Title level={3} className="mb-3">
            <h3 className="font-24 font-700 mb-0 mt-3">
              {location.state?.key === "salon"
                ? t("InviteSalonList")
                : "Restaurantliste"}
            </h3>
          </Title>
          <LeftCircleOutlined
            onClick={() => window.history.back()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "30px"
            }}
          />
        </Row>
        <Divider>
          {t("InviteNearby")}
          {location.state?.key === "salon" ? t("InviteSalon") : "Restaurant"}
        </Divider>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <button
              className={`width-100 text-white border-radius-5 mainBgColor py-2 ${
                !showInvited ? "active-button" : ""
              }`}
              style={{ border: "none" }}
              onClick={() => setShowInvited(false)}
            >
              {t("Invite")}
            </button>
          </Col>
          <Col span={12}>
            <button
              className={`width-100 text-white border-radius-5 py-2 ${
                showInvited ? "active-button" : ""
              }`}
              style={{ background: "#f4c500", border: "none" }}
              onClick={() => {
                setShowInvited(true);
                fetchInvitations(); // Function call inside arrow function
              }}
            >
              {t("Invited")}
            </button>
          </Col>
        </Row>

        <Table
          bordered
          className="mt-3 border-radius-10"
          columns={[
            {
              title: "S.No",
              dataIndex: "index",
              key: "index",
              width: 50,
              responsive: ["lg"],
              render: (_, __, index) => index + 1
            },
            {
              title: t("InviteName"),
              dataIndex: "name",
              width: 100,
              key: "name",
              render: (text) => <b>{text}</b>
            },
            {
              title: "",
              dataIndex: "image",
              key: "image",
              width: 50,
              render: (_, record) => (
                <>
                  {record.isInvited ? (
                    <img
                      src={potaliClosed}
                      loading="lazy"
                      alt={record.isInvited ? t("Invited") : t("Invite")}
                    />
                  ) : (
                    <img
                      src={potaliOpen}
                      loading="lazy"
                      alt={record.isInvited ? t("Invited") : t("Invite")}
                    />
                  )}
                </>
              )
            },
            {
              title: t("InviteAction"),
              key: "action",
              width: 60,
              align: "center",
              render: (_, record) => (
                <Button
                  className="border-radius-50"
                  disabled={record.isInvited}
                  style={{
                    backgroundColor: record.isInvited ? "grey" : "#0066CC",
                    borderColor: record.isInvited ? "grey" : "#0066CC",
                    color: "#fff"
                  }}
                  onClick={() =>
                    inviteFxn(record.name, record.address, record.city)
                  }
                >
                  {record.isInvited ? t("Invited") : t("Invite")}
                </Button>
              )
            }
          ]}
          dataSource={data}
          pagination={false}
          scroll={{ y: 620 }}
          locale={{ emptyText: <Empty description={t("InviteNoData")} /> }}
        />
      </>
    );
  };
  return (
    <>
      {isMobile ? (
        <div className="mb-5 pb-5">
          {loading ? (
            <>Loading........</>
          ) : (
            <>
              <div className="px-3" style={{ marginTop: "70px" }}>
                {tableContent()}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          {loading ? (
            <>Loading........</>
          ) : (
            <div
              style={{ marginTop: "100px" }}
              className="mission profilePages"
            >
              <Row className="align-items-center justify-content-center">
                <Col lg={12} className="commonShadowAndRound p-5">
                  {tableContent()}
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <BottomFooter />
    </>
  );
}
