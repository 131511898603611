import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { CheckCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import axios from "axios";

import tflBagToCard from "../../assets/mobile/tflPageimg.png";
import { Row, Col, Table, Divider, Popconfirm, Collapse, message } from "antd";

import { Button } from "react-bootstrap";
import smallCoin from "../../assets/SMALLCOIN.webp";
import BottomFooter from "../Footer/BottomFooter";
// Import for Language translater
import { useTranslation } from "react-i18next";

import { Trans } from "react-i18next";
import i18n from "./../../i18n";
function TFLCoinExchange() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const [searchParams] = useSearchParams();
  let navigate = useNavigate("");
  const { Panel } = Collapse;
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [coinCode, setCoinCode] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [userPoints, setUserPoints] = useState("0");
  const [pointsRedeemed, setPointsRedeemed] = useState("0");
  const [userPointData, setUserPointData] = useState([]);

  let user = useSelector((state) => state.userReducer);
  const userID = user._id;
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getCoupons();
    getPoints();
  }, []);

  const getPoints = async () => {
    let cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };
    setLoading(true);
    await axios
      .post(`${apiUrl}${tDriveEndpoint}appuser/getuserpoints`, cardHistoryData)
      .then((response) => {
        // console.log("response", response.data.data.points);
        localStorage.setItem("userPoints", response.data.data.points);
        setUserPoints(response.data.data.points);
        setPointsRedeemed(response.data.data.pointsRedeemed);
        setUserPointData(response.data.data.pointsArr);
        setLoading(false);
      })
      .catch((error) => {
        // console.log("---error user---", error);
      });
  };

  const getCoupons = async () => {
    const cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };
    setLoading(true);

    await axios
      .post(`${apiUrl}${tDriveEndpoint}appuser/getusercoupons`, cardHistoryData)
      .then((response) => {
        setCouponData(response.data.data.coupons);
        setLoading(false);
      })
      .catch((error) => {});
  };
  const columnsData = [
    {
      title: t("ExchangeGiftCard"),
      dataIndex: "nok",
      key: "giftCardInNOK"
    },
    {
      title: t("ExchangeNumberCoins"),
      dataIndex: "points",
      key: "coins",
      render: (text) => <strong>{text}</strong>
    },
    {
      title: t("ExchangeAction"),
      key: "action",
      render: (_, record) =>
        userPointData.length >= 1 ? (
          <Popconfirm
            title={`${t("ExchangePopUp1")} ${record.points} ${t(
              "ExchangePopUp2"
            )} ${record.nok} ${t("ExchangePopUp")}?`}
            onConfirm={() => handleRedeem(record._id)}
            disabled={userPoints === 0}
          >
            <Button
              type="primary"
              className="width-100"
              onClick={() => {
                if (userPoints === 0) {
                  message.error(t("NotEnoughCoins"));
                }
              }}
            >
              {t("ExchangeRedeem")}
            </Button>
          </Popconfirm>
        ) : (
          ""
        )
    }
  ];
  const handleRedeem = (id) => {
    let payload = {
      appUserId: userID,
      redeemId: id
    };
    async function fetchData() {
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/treeDrive/appuser/redeempoints`,
          payload
        );

        if (response.data.status === 500) {
          message.warning(response.data.message);
          // alert(response.data.message);
        } else if (response.data.status == 403) {
          message.warning(t("ExchangeMessage") || response.data.message);
        } else {
          message.warning(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Reload after 2 seconds
        }

        setCoinCode(response.data.data.coupon.coupanCode);
        setOpenModal(true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const content = () => {
    return (
      <>
        <Row className="justify-content-center p-3">
          <Row className="bg-gradient mb-3 p-3 border-radius-10">
            <Col
              span={24}
              className="d-flex align-items-center justify-content-center font-700 mt-1 text-white font-18 text-center"
            >
              {t("TFLExchangeGift")}
            </Col>
            <Col
              span={24}
              className="d-flex align-items-center mt-1 justify-content-center"
            >
              <img
                src={tflBagToCard}
                loading="lazy"
                alt="coins"
                width={"100%"}
              />
            </Col>
          </Row>
          <Col
            span={24}
            className=" pointsDisplay d-flex flex-column justify-content-center align-items-start"
          >
            <p className="font-18 font-700 mb-1">
              {t("ExchangeAvailableCoins")}: {userPoints}{" "}
              <img src={smallCoin} alt="Coin" />
            </p>
            <p className="font-16 mb-1">
              {t("ExchangeUsedCoins")}: {pointsRedeemed}{" "}
              <img src={smallCoin} alt="Coin" />
            </p>
            <p className="font-16 mb-1">
              {t("ExchangeTotalCoins")}: {userPoints + pointsRedeemed}{" "}
              <img src={smallCoin} alt="Coin" />
            </p>
          </Col>
          <Col span={24}>
            {" "}
            <i>
              <p className="font-12">{t("ExchangeCoinsUpdate")}</p>
            </i>
          </Col>
          <Divider />
          <Col span={24} className="d-flex justify-content-center">
            <p className="font-16">{t("ExchangeRedemptionScheme")}</p>
          </Col>
          <Col span={24}>
            <Table
              className="pointsTable"
              columns={columnsData}
              dataSource={userPointData}
              bordered
            />
          </Col>
          <Col span={24} className="pt-2">
            <p>
              <i>
                <u>
                  *{t("ExchangePleaseVisit")}:{" "}
                  <a
                    href="https://www.ezygo.no"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>
                      <u>{t("EzyGoShopLink")}</u>
                    </i>
                  </a>
                  !
                </u>
              </i>
            </p>
          </Col>
        </Row>
        <Row className="px-3 bottomThought pt-4 pb-5">
          <Col span={24} className="mt-3 sansita-black">
            <p>{t("MoreEarnCollect")}</p>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      {isMobile && (
        <div className="vh90andScroll">
          <Row className="justify-content-between mt-2 px-3">
            <h3 className="mb-0 font-700 font-24">{t("TFLDigitalCoins")}</h3>
            <LeftCircleOutlined
              onClick={() => window.history.back()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px"
              }}
            />
          </Row>
          {content()}
        </div>
      )}
      {!isMobile && (
        <div style={{ marginTop: "64px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col span={8} className="commonShadowAndRound my-5">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
export default TFLCoinExchange;
