import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

import axios from "axios";

import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";

import { Row, Col, Divider, message } from "antd";
import { Form, FloatingLabel, Button, Modal, Alert } from "react-bootstrap";

import { isMobile } from "react-device-detect";

import circleLogin from "../../assets/circleLogin.webp";
import pinLogin from "../../assets/pinLogin.webp";

import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
// Import for Language translater
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import i18n from "./../../i18n";
export default function Reset() {
  let user = useSelector((state) => state.userReducer);
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const navigate = useNavigate("");

  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);

  let [statePhone, setStatePhone] = useState("");

  const [modalShow, setModalShow] = useState(false);

  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let phoneNumber = e.target.inputPhone.value;
    let countryCode = e.target.inputCountryCode.value;

    setStatePhone(phoneNumber);

    // console.log("---phone, countrycode---", phoneNumber, countryCode);

    let inputFields = [phoneNumber, countryCode];

    if (!validateForm(inputFields)) {
      // console.log(phoneNumber);
      setShowAlert(true);
      message.warning(t("FillAllRequiredFields"));
      // setAlertType("danger");
      // setAlertMsg("Please fill all the required fields!");
    } else {
      if (phoneNumber.length > 10 || phoneNumber.length < 8) {
        setShowAlert(true);
        message.warning(t("InfoValidNumber"));
        // setAlertType("danger");
        // setAlertMsg("Please enter a valid Phone number!");
        return;
      }

      // console.log("---posting data----");

      setShowAlert(true);
      // setAlertType("warning");
      // setAlertMsg("Vennligst vent...");
      message.warning(t("VerifyPleaseWait"));

      //try{

      let formData = {
        countryCode: countryCode,
        phone: phoneNumber
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/appforgetpassword`, formData)
        .then((response) => {
          // console.log("---response server---", response);

          if (response.data.status == 200) {
            // console.log("----ok----");

            setShowAlert(true);
            message.success(t("OTPSentOnMobileNo"));
            // setAlertType("success");
            // setAlertMsg(
            //   "OTP sendes til ditt registrerte Mobilnummer for gjenoppretting av passord."
            // );

            setModalShow(true);
          } else {
            // console.log("----error----");

            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          // console.log("---error user---", error.message);
          setShowAlert(true);
          // setAlertType("danger");
          // setAlertMsg(
          //   "Feil: Ugyldig informasjon angitt, vennligst sjekk informasjonen din fï¿½r du sender inn skjemaet."
          // );
          message.error(t("InvalidInfoAdded"));
        });
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    let newPassword = e.target.inputPassword.value;
    let otp = e.target.inputOTP.value;

    // console.log("---phone, countrycode---", statePhone, newPassword, otp);

    let inputFields = [newPassword, otp];

    if (!validateForm(inputFields)) {
      setShowAlert(true);
      // setAlertType("danger");
      // setAlertMsg("Please fill all the required fields!");
      message.warning(t("FillAllRequiredFields"));
    } else {
      // console.log("---posting data----");

      setShowAlert(true);
      // setAlertType("warning");
      // setAlertMsg("Vennligst vent...");
      message.warning(t("VerifyPleaseWait"));

      let formData = {
        phone: statePhone,
        otp: otp,
        newPassword: newPassword
      };

      await axios
        .post(
          `${apiUrl}${tDriveEndpoint}appuser/appresetpasswordwithotp`,
          formData
        )
        .then((response) => {
          // console.log("---response server---", response);

          if (response.data.status == 200) {
            // console.log("----ok----");

            setShowAlert(true);
            message.success(t("PasswordResetSuccess"));
            // setAlertType("success");
            // setAlertMsg("Password reset successfully.");

            navigate("/login");
          } else {
            // console.log("----error----");

            setShowAlert(true);
            message.error(response.data.message);
            // setAlertType("danger");
            // setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          // console.log("---error user---", error.message);
          setShowAlert(true);
          message.error(t("InvalidInfoAdded"));
          // setAlertType("danger");
          // setAlertMsg(
          //   "Feil: Ugyldig informasjon angitt, vennligst sjekk informasjonen din fï¿½r du sender inn skjemaet."
          // );
        });
    }
  };

  useEffect(() => {
    if (user.token) {
      navigate("/profile");
    }
  }, []);

  //Modal Component
  const ResetPasswordModal = (props) => {
    return (
      <Modal
        {...props}
        animation={false}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-dark">
            {t("EnterOTP")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={handleResetPassword}>
            <Form.Group className="row mb-3" controlId="formBasicPassword">
              <Col span={24}>
                <FloatingLabel
                  controlId="floatingInputGridd"
                  label={t("NewPassword")}
                >
                  <Form.Control required type="password" name="inputPassword" />
                </FloatingLabel>
              </Col>

              <Col span={24} className="mt-3">
                <FloatingLabel controlId="floatingInputGriid" label="OTP*">
                  <Form.Control required type="text" name="inputOTP" />
                </FloatingLabel>
              </Col>
            </Form.Group>

            {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}
            <div className="d-flex justify-content-between">
              <Button
                variant="primary"
                type="submit"
                className="width-100 mt-3 py-2 border-radius-10"
              >
                {t("EnterOTP")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };
  const commonForgetPass = () => {
    return (
      <>
        <Row
          className="d-flex flex-column align-items-center justify-content-center logBox"
          style={{ borderRadius: "20px" }}
        >
          {" "}
          <Col span={24} className="text-center width-100">
            {" "}
            <h4 class="font-18 font-700">Ezygo</h4>
            <h4
              class="font-18 font-700 mt-1 d-flex align-items-center justify-content-center"
              style={{ color: "#0066CC " }}
            >
              <img src={circleLogin} loading="lazy" />
              &nbsp; {t("LoginSimplified")} !&nbsp;
              <img src={pinLogin} loading="lazy" />
            </h4>
            <Divider>{t("ForgetPassword")}</Divider>
          </Col>
          <Col span={24} className="text-center width-100 mt-2">
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="row mb-3" controlId="formBasicEmail">
                <Col className="d-flex">
                  <Form.Select
                    style={{ width: "30%" }}
                    aria-label="Velg landskode"
                    name="inputCountryCode"
                  >
                    <option value="47">+47</option>
                    <option value="91">+91</option>
                  </Form.Select>
                  <Form.Control
                    style={{ width: "100%" }}
                    required
                    type="number"
                    placeholder={t("MobilePLaceholder")}
                    name="inputPhone"
                  />
                </Col>
              </Form.Group>
              {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}{" "}
              <Button
                variant="primary"
                type="submit"
                className="width-100 py-2 border-radius-10"
              >
                {t("SendOTP")}
              </Button>{" "}
              <Col span={24} className="d-flex justify-content-between">
                <p className="py-2 width-100">
                  {t("ResetNewUser")} <br />
                  <NavLink to="/signup" className=" col-1">
                    {t("ResetRegister")}
                  </NavLink>
                </p>
                <p className="py-2 width-100">
                  {t("ResetBack")}&nbsp;
                  <br />
                  <NavLink to="/login" className=" col-1">
                    {t("FormLogin")}
                  </NavLink>
                </p>
              </Col>{" "}
            </Form>
          </Col>
          <Col span={24}>
            <NavLink to="/home" style={{ color: "#0066CC" }}>
              {" "}
              <u>{t("GuestUser")}</u>
            </NavLink>{" "}
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages  p-5">
          <div className="d-flex justify-content-center py-5 mt-5 ">
            <Col md={9} xl={6} className="pb-3">
              {commonForgetPass()}
            </Col>
          </div>
        </section>
      )}

      {isMobile && (
        <section
          className="forgetBg p-2 d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          {commonForgetPass()}
        </section>
      )}
      <ResetPasswordModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
