import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col, Descriptions, Table } from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { apiUrl } from "../../helper/endpoint";
// Import for Language translater
import { useTranslation } from "react-i18next";
export default function TFLOrderSummary() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations
  let paymentType = "Dintero";
  const paramUrl = window.location.href; // Get the current URL
  const encUrl = new URL(paramUrl); // Parse the URL
  const params = new URLSearchParams(encUrl.search); // Extract the search/query string

  // Extract `orderId` from the query parameters
  const orderId = params.get("orderId");

  let merchant_reference = params.get("merchant_reference");
  let transaction_id = params.get("transaction_id");
  let [stateLoading, setStateLoading] = useState(true);
  let [paymentFailed, setPaymentFailed] = useState(false);
  let [resData, setResData] = useState("");
  let [productsData, setProductsData] = useState([]);

  const checkVendorPayment = async (data) => {
    try {
      let res = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedrivepayment/orderPaymentCheckTfl`,
        data
      );
      if (res && res.status == 200) {
        setResData(res.data.data);
        setProductsData(res.data.data.items);
        setPaymentFailed(false);
      } else {
        setPaymentFailed(true);
      }
    } catch (error) {
      setPaymentFailed(true);
    }
  };
  const checkCouponSuccessPayment = async () => {
    try {
      let res = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedrivepayment/orderPaymentCheckTfl`
      );
      if (res && res.status == 200) {
        setPaymentFailed(false);
      } else {
        setPaymentFailed(true);
      }
    } catch (error) {
      setPaymentFailed(true);
    }
  };

  const deleteDataFromLocal = () => {
    localStorage.setItem("TFLCartData", []);
  };

  useEffect(() => {
    let data = {
      id: merchant_reference,
      transaction_id
    };
    checkVendorPayment(data);
  }, []);

  const OrderColumns = () => [
    {
      title: "Product",
      width: "37%",
      key: "name",
      dataIndex: "name"
    },
    {
      title: "Total Quantity",
      width: "37%",
      key: "quantity",
      render: (text, record) => {
        return record.availQuantity
          ? `${record.quantity}pkt X ${record.availQuantity}${record.unitType}`
          : `${record.quantity}`;
      }
    },
    {
      title: `Amount(Kr.) `,
      width: "26%",
      key: "name",
      render: (text, record) => {
        return `Kr.${record.totalAmount}`;
      }
    }
  ];
  return (
    <>
      {" "}
      <div className="formWrapper pt-5" id="vendoStatus">
        <Col span={12}>
          <Card bordered={false} className="cardContainer">
            {!paymentFailed ? (
              <Row className="justify-content-start p-3 flex-column">
                <div className="py-3 d-flex justify-content-start align-items-center">
                  {" "}
                  <AiOutlineCheckCircle
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#0066CC",
                      marginRight: "10px"
                    }}
                  />
                  <h4 className="text-white mb-0">{t("SummeryOrderPlaced")}</h4>
                </div>
                <div>
                  <p>
                    <b>
                      {t("YourOTP")}: {resData.otp}
                    </b>
                    .
                  </p>
                  <p style={{ fontSize: "14px" }}></p>
                  <>
                    <Descriptions
                      title={t("SummaryOrderInformation")}
                      className="mt-3"
                    ></Descriptions>{" "}
                    <Table
                      id="tablePrint"
                      columns={OrderColumns()}
                      dataSource={productsData}
                      striped
                      bordered
                      hover
                    ></Table>
                    <Descriptions className="pt-5">
                      <Descriptions.Item label="Total">
                        <b> Kr. {resData.totalAmount}</b>
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                  <Descriptions title="User Info" className="mt-3">
                    <Descriptions.Item label="Address">
                      {resData?.customerDetail?.address}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="py-3 goBackBtn">
                  <NavLink
                    className="orderBtn"
                    to="/tflMarket"
                    onClick={deleteDataFromLocal}
                  >
                    {t("TFLOrderExplore")}
                  </NavLink>
                </div>
              </Row>
            ) : (
              <Row className="justify-content-start p-3 flex-column">
                <div className="py-3 d-flex justify-content-start flex-column align-items-center">
                  {" "}
                  <AiOutlineClose
                    className="height100px width100px"
                    style={{
                      color: "red",
                      marginRight: "10px"
                    }}
                  />
                  <h4 className=" mb-0">{t("TFLOrderFailed")}</h4>
                </div>
                <div></div>
                <div>
                  <p style={{ fontSize: "14px" }}></p>
                </div>
                <Row className="py-3 goBackBtn">
                  {" "}
                  <NavLink
                    to="/tflMarketCart"
                    className="orderBtn width-100 text-center"
                  >
                    {t("TFLOrderBack")}
                  </NavLink>
                  <NavLink
                    to="/tflMarket"
                    className="orderBtn width-100 text-center  mt-3"
                    onClick={deleteDataFromLocal}
                  >
                    {t("TFLOrderExplore")}
                  </NavLink>
                </Row>
              </Row>
            )}
          </Card>
        </Col>
      </div>
    </>
  );
}
