import React from "react";
import { useNavigate } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import icon1 from "../../assets/mobile/ezygo.webp";
import icon2 from "../../assets/mobile/history.webp";
import icon4 from "../../assets/mobile/gift_card.webp";
import icon5 from "../../assets/mobile/share.webp";
import ProfileIcon from "../../assets/mobile/A-Board.webp";
import { isMobile } from "react-device-detect";
// Import for Language translater
import { useTranslation } from "react-i18next";

import { Trans } from "react-i18next";
import i18n from "./../../i18n";

function BottomFooter() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  let navigate = useNavigate();
  return (
    <>
      {isMobile ? (
        <Container fluid>
          <Row className="position-fixed mobileNavBottom bottom-0 width-100 p-1 justify-content-between">
            <Col span={4} className="text-center p-0">
              <a
                onClick={() => {
                  navigate("/home");
                }}
                className="d-flex flex-column align-items-center"
              >
                <img alt="EzyGo" src={icon1} />
                Ezygo
              </a>
            </Col>
            <Col span={5} className="text-center p-0">
              <a
                onClick={() => {
                  navigate("/tflgiftcard");
                }}
                className="d-flex flex-column align-items-center"
              >
                <img src={icon4} loading="lazy" />
                {t("TFLAgentCardTitle")}
              </a>
            </Col>{" "}
            <Col span={4} className="text-center p-0">
              <a
                onClick={() => {
                  navigate("/orderHistory");
                }}
                className="d-flex flex-column align-items-center"
              >
                <img alt="TFL Coins" src={icon2} loading="lazy" />
                {t("NavHstory")}
              </a>
            </Col>{" "}
            <Col span={4} className="text-center p-0">
              <a
                onClick={() => {
                  navigate("/a-board");
                }}
                className="d-flex flex-column align-items-center"
              >
                <img alt="Profil" src={ProfileIcon} loading="lazy" />
                {t("AboardArchive")}
              </a>
            </Col>{" "}
            <Col span={4} className="text-center p-0">
              <a
                onClick={() => {
                  navigate("/shareReferral");
                }}
                className="d-flex flex-column align-items-center"
              >
                <img src={icon5} loading="lazy" />
                {t("NavShare")}
              </a>
            </Col>{" "}
          </Row>
        </Container>
      ) : (
        ""
      )}
    </>
  );
}
export default BottomFooter;
