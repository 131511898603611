import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Row, Col } from "antd";

import contactUs from "../../assets/Doll Web/contactus.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import Modal from "../Modal/Modal";
import Form from "../ContactForm/Form";

// Import for Language translater
import { useTranslation } from "react-i18next";

import { Trans } from "react-i18next";
import i18n from "./../../i18n";

export default function Contact() {
  let navigate = useNavigate();
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const [isOpen, setisOpen] = useState(false);
  const [isOpenForm, setisOpenForm] = useState(false);
  const [isOpenForm2, setisOpenForm2] = useState(false);
  const handleClose = () => {
    setisOpen(false);
  };
  const handleClose2 = () => {
    setisOpen(false);
  };
  const onCloseForm = () => {
    setisOpenForm(false);
  };
  const onCloseForm2 = () => {
    setisOpenForm2(false);
  };
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);
  return (
    <>
      <div style={{ marginTop: "64px" }} className="bannerImg">
        <Row className="align-items-center justify-content-between px-5">
          <Col span={12}>
            <h3
              class="font-55 font-700 sansita-black mb-1"
              style={{ color: "#0066CC" }}
            >
              <Trans i18nKey="KnowMoreTitle" components={{ br: <br /> }} />
            </h3>
            <br />
            <p className="font-24 font-700 sansita-black mb-0">
              {t("VisitText")}:{" "}
              <a href="https://ezygo.no/" className="text-black">
                {t("EzyGoShopLink")}
              </a>
            </p>
            <p className="mb-0"> or</p>
            <button
              className="mainBgColor font-24 text-white border-radius-5 px-5 py-1"
              style={{ border: "none" }}
              onClick={() =>
                (window.location.href = "https://ezygo.no/contact")
              }
            >
              {t("SubmitButton")}
            </button>

            <br />
            <br />
            <div>
              <a href="/privacy" className="footerLink text-black font-20">
                {t("PrivacyPolicyText")}
              </a>{" "}
              |{" "}
              <a href="/terms" className="footerLink text-black font-20">
                {t("GeneralText")}
              </a>
            </div>
            <div>
              <a
                href="/salesAndCancellation"
                className="footerLink text-black font-20"
              >
                {t("SaleText")}
              </a>{" "}
              |{" "}
              <a href="/scamAlert" className="footerLink text-black font-20">
                {t("FraudAlertText")}
              </a>{" "}
              |{" "}
              <a href="/disclaimer" className="footerLink text-black font-20">
                {t("DisclaimerText")}
              </a>
            </div>
          </Col>
          <Col span={12}>
            <img src={contactUs} alt="contactUs" width={"100%"} />
          </Col>
        </Row>
      </div>
      <Modal isOpen={isOpen} handleClose={handleClose} />{" "}
      {isOpenForm && <Form onCloseForm={onCloseForm} />}
      {isOpenForm2 && <Form onCloseForm2={onCloseForm2} />}
    </>
  );
}
