import React from "react";
import { Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import BottomFooter from "../Footer/BottomFooter";
import slide1 from "../../assets/mobile/coins/slide-1-100-100.webp";
import slide2 from "../../assets/mobile/coins/slide-2-100-100.webp";
import slide3 from "../../assets/mobile/coins/slide-3-100-100.webp";
import slide4 from "../../assets/mobile/coins/slide-4-100-100.webp";
import slide5 from "../../assets/mobile/coins/slide-5-100-100.webp";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import i18n from './../../i18n';

function Modal1Slide() {
   //For Language Translater
   const { t, i18n } = useTranslation(); // Hook to access translations

  const { modalId } = useParams();
  const navigate = useNavigate();

  // Data for different modals, including images
  const modalData = {
    1: {
      title: t("ModalActivateTitle"),
      description: [
        t("ModalRegister"),
        t("ModalSetup"),
        t("ModalActivate")
      ],
      image: slide1
    },
    2: {
      title: t("ModalAgentWork"),
      description: [
        t("ModalExplore"),
        t("ModalAccess"),
        t("ModalEarnTFL")
      ],
      image: slide2
    },
    3: {
      title: t("ModalEarnCoins"),
      description: [
        t("ModalCollectCoins"),
        t("ModalStayActive"),
        t("ModalSaveCoins")
      ],
      image: slide3
    },
    4: {
      title: t("ModalBuyGift"),
      description: [
        t("ModalPurchase"),
        t("ModalGiftAgent"),
        t("ModalSpend")
        // "Kjøp agent-e-kort med forhåndsverdi for ekstra fordeler.",
        // "Gaveagent e-kort til andre og nyt delte besparelser.",
        // "Bruk klokt og tjen ekstra mynter på hver transaksjon med Agent E-kortet ditt."
      ],
      image: slide4
    },
    5: {
      title: t("ModalHold"),
      description: [
        t("ModalLonger"),
        t("ModalRedeem"),
        t("ModalCollecting")
      ],
      image: slide5
    }
  };

  const data = modalData[modalId];

  if (!data) {
    return <div>{t("ModalID")}</div>;
  }

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center vh90andScroll"
        style={{ background: "#d7f1ff" }}
      >
        <div
          className="text-center"
          style={{
            background: "#ffffff",
            borderRadius: "15px",
            width: "90%",
            maxWidth: "400px",
            padding: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
          }}
        >
          <img
            src={data.image}
            alt="Modal Graphic"
            style={{ width: "200px" }}
          />
          <h3 style={{ color: "#2d8af7", margin: "20px 0" }}>{data.title}</h3>
          <ul
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "0",
              textAlign: "left"
            }}
          >
            {data.description.map((item, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "10px",
                  fontSize: "14px",
                  color: "#444"
                }}
              >
                <span
                  role="img"
                  aria-label="icon"
                  style={{ marginRight: "10px" }}
                >
                  📌
                </span>
                {item}
              </li>
            ))}
          </ul>
          <Button
            variant="primary"
            onClick={() => navigate("/home")}
            style={{
              marginTop: "20px",
              background: "#2d8af7",
              border: "none",
              padding: "10px 20px"
            }}
          >
            {t("CloseButton")}
          </Button>
        </div>
      </div>
      <BottomFooter />
    </>
  );
}

export default Modal1Slide;
