import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { couponAction } from "../../store/slices/couponslices";
import { Row, Card, Col } from "antd";
import { s3url } from "../../helper/endpoint";
import {
  CloseCircleOutlined,
  HeartFilled,
  EnvironmentFilled,
  ClockCircleOutlined
} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import offerTag from "../../assets/mobile/offerTag.webp.webp";
import BottomFooter from "../Footer/BottomFooter";
// Import for Language translater
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import i18n from "./../../i18n";

const ProductCard = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  let dispatch = useDispatch();
  const [resClosingTime, setResClosingTime] = useState(null);
  let root = useSelector((state) => state.rootReducer);

  let resSplit = props.res.name.split(" ");
  let resName = props.res.name;
  let resStatus = props.res.availability.status;

  let tdDriveOut = props.res.isTreeDriveDriveOutAllowed;
  let tdPickup = props.res.isTreeDrivePickUpAllowed;

  let tdRestDriveOut = props.res.isRestDriveOut;
  let tdRestPickUp = props.res.isRestPickup;

  let driveoutPermission = tdDriveOut && tdRestDriveOut ? true : false;
  let takeawayPermission = tdPickup && tdRestPickUp ? true : false;

  if (resSplit.length > 2) {
    resName = props.res.name.split(" ").slice(0, 2).join(" ") + "....";
  }

  let MaxDiscountTD = Math.max(
    props.res.tdDriveOutDiscountRange.discount1,
    props.res.tdDriveOutDiscountRange.discount2,
    props.res.tdDriveOutDiscountRange.discount3
  );
  let MaxDiscountTDTakeAway = Math.max(
    props.res.tdTakeAwayDiscountRange.discount1,
    props.res.tdTakeAwayDiscountRange.discount2,
    props.res.tdTakeAwayDiscountRange.discount3,
    props.res.tdTakeAwayDiscountRange.discount4
  );

  let maxOfBoth = Math.max(MaxDiscountTD, MaxDiscountTDTakeAway);

  let dayCheck = () => {
    const d = new Date().getDay();
    let weekday = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday"
    ];
    let finalTimming = props.res[weekday[d]].openingTime;
    return finalTimming;
  };
  let flashTag = () => {
    if (driveoutPermission || takeawayPermission) {
      if (resStatus === false) {
        return (
          <div className={resStatus === false ? "discountTag" : "discountTag"}>
            <div className="d-flex align-items-center">
              <ClockCircleOutlined />
              &nbsp;
              <p className="mb-0">
                {t("ProductOpen")}
                {dayCheck()}
              </p>
            </div>
          </div>
        );
      }
    }

    if (driveoutPermission && takeawayPermission) {
      return (
        <>
          {root.orderType === "reserveTable" ? null : (
            <div
              className={
                MaxDiscountTDTakeAway !== 0 ? "discountTag" : "discountTagNone"
              }
            >
              {maxOfBoth != 0 && (
                <p className="mb-0 textBlack d-flex align-items-center">
                  <>
                    <HeartFilled className="colorRed" />
                    &nbsp;{t("ProductDiscount")}
                    <span>&nbsp;{maxOfBoth}%</span>
                  </>
                </p>
              )}
            </div>
          )}
        </>
      );
    }
    if (driveoutPermission && !takeawayPermission) {
      return (
        <div className={MaxDiscountTD != 0 ? "discountTag" : "discountTagNone"}>
          {MaxDiscountTD != 0 && (
            <p className="textBlack d-flex align-items-center mb-0">
              <HeartFilled className="colorRed" />
              &nbsp;Rebate upto:
              <span>&nbsp;{MaxDiscountTD}%</span>
            </p>
          )}
        </div>
      );
    }
    if (!driveoutPermission && takeawayPermission) {
      return (
        <div
          className={
            MaxDiscountTDTakeAway != 0 ? "discountTag" : "discountTagNone"
          }
        >
          {MaxDiscountTDTakeAway != 0 && (
            <p className="textBlack d-flex align-items-center mb-0">
              {" "}
              <HeartFilled className="colorRed" />
              &nbsp;Rebate upto:
              <span>&nbsp;{MaxDiscountTDTakeAway}%</span>
            </p>
          )}
        </div>
      );
    }
  };

  let [tags, setTags] = useState([]);

  let [resClass, setResClass] = useState("disabledRes");

  useEffect(() => {
    let tags = [];

    if (root.orderType == "reserveTable") {
      setResClass("");
    }

    if (root.orderType != "reserveTable") {
      if (
        props.res.isTreeDriveDriveOutAllowed == true &&
        props.res.isRestDriveOut == true
      ) {
        tags.push(t("HeaderDriveOut"));
        // tags.push("Utkjøring");
        setResClass("");
      }

      if (
        props.res.isTreeDrivePickUpAllowed == true &&
        props.res.isRestPickup == true
      ) {
        tags.push(t("HeaderPickup"));
        // tags.push(" Henting");
        setResClass("");
      }
    }

    setTags(tags);
  }, []);
  useEffect(() => {
    localStorage.removeItem("appliedCouponCode");
    localStorage.removeItem("couponValue");
    // console.log("Coupon removed from localStorage");
    dispatch(couponAction.resetCouponState()); // Dispatch the action to reset coupon state
  }, [dispatch]);

  return (
    <>
      {isMobile && (
        <Col span={24} className="mobileCardRest mb-3 position-relative ">
          {" "}
          <Card
            className={`${resClass} border-radius-10 restCard pr-2`}
            disabled={true}
            title=""
            bordered={false}
            onClick={() => {
              if (props.res.availability.status && resClass == "") {
                props.onResClickHandler(props.res);
              }
            }}
          >
            <Row>
              <Col span={8} className="d-flex align-items-center">
                {" "}
                <img
                  alt="products"
                  src={`${s3url}/${props.res.logo}`}
                  className="width-100"
                  style={{ borderRadius: "10px 0 0 10px " }}
                />
              </Col>
              <Col span={14} className="pt-3 pb-2">
                <div
                  className="productDescription"
                  style={{ paddingLeft: "10px" }}
                >
                  <div className="title">
                    <h3 className="font-bold mb-0 d-flex font-16 align-items-center ">
                      {resName}
                    </h3>
                    {props.res.description != "0" && (
                      <p className="mb-0">
                        <i>{props.res.description}</i>
                      </p>
                    )}
                    <p className="d-flex align-items-end font-400 mb-0">
                      <EnvironmentFilled />
                      {props.res.address.city.length < 13
                        ? props.res.address.city
                        : props.res.address.city.substr(0, 13) + "..."}
                    </p>
                  </div>
                  <div>{flashTag()}</div>
                  {props.res.availability.status ? (
                    <div className="subTitleTag d-flex pt-1 pr-2">
                      {tags.map((e, i) => {
                        return (
                          <p className="border-radius-5 font-400" key={i}>
                            {e}
                          </p>
                        );
                      })}
                      {root.orderType != "reserveTable" && tags.length < 1 && (
                        <p className="redClosedTag border-radius-50 text-white font-400 ">
                          <CloseCircleOutlined />
                          &nbsp;&nbsp; {t("ProductClosed")}
                        </p>
                      )}
                    </div>
                  ) : (
                    <>
                      <p className="redClosedTag border-radius-50 text-white font-400 ">
                        <CloseCircleOutlined />
                        &nbsp;&nbsp; {t("ProductClosed")}
                      </p>
                    </>
                  )}{" "}
                </div>

                {props.res.resOfferEnabled && (
                  <img
                    alt="Offer"
                    src={offerTag}
                    className="offerTagMobile positionAbsolute"
                    style={{
                      width: "50px",
                      right: "-20px",
                      top: 0
                    }}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {!isMobile && (
        <Col md={12} xl={8} className="mobileCardRest mb-3 position-relative">
          <Card
            className={`${resClass} border-radius-10 restCard px-2 height-100`}
            disabled={true}
            title=""
            bordered={false}
            onClick={() => {
              if (props.res.availability.status && resClass == "") {
                props.onResClickHandler(props.res);
              }
            }}
          >
            <Row className="justify-content-between">
              <Col
                span={6}
                className="d-flex align-items-center justify-content-center"
              >
                {" "}
                <img
                  alt="products"
                  src={`${s3url}/${props.res.logo}`}
                  // className="width-100 height-100"
                  style={{ borderRadius: "10px 0 0 10px", width: "100%" }}
                />
              </Col>
              <Col span={13} className="pt-3 pb-2">
                <div className="productDescription">
                  <div className="title">
                    <h3 className="font-bold mb-0 d-flex font-20 align-items-center ">
                      {resName}
                    </h3>
                    {props.res.description != "0" && (
                      <p className="mb-0">
                        <i>{props.res.description}</i>
                      </p>
                    )}

                    <p className="d-flex align-items-center  font-400 mb-0">
                      <EnvironmentFilled />
                      {props.res.address.city.length < 13
                        ? props.res.address.city
                        : props.res.address.city.substr(0, 13) + "..."}
                    </p>
                  </div>
                  <div>{flashTag()}</div>
                  {props.res.availability.status ? (
                    <div className="subTitleTag d-flex pt-1 pr-2">
                      {tags.map((e, i) => {
                        return (
                          <p className="border-radius-5 font-400" key={i}>
                            {e}
                          </p>
                        );
                      })}
                      {root.orderType != "reserveTable" && tags.length < 1 && (
                        <p className="redClosedTag border-radius-50 text-white font-400">
                          <CloseCircleOutlined />
                          &nbsp;&nbsp; {t("ProductClosed")}
                        </p>
                      )}
                    </div>
                  ) : (
                    <>
                      <p className="redClosedTag border-radius-50 text-white font-400">
                        <CloseCircleOutlined />
                        &nbsp;&nbsp;{t("ProductClosed")}t
                      </p>
                    </>
                  )}{" "}
                </div>

                {/* {props.res.resOfferEnabled && (
                  <img
                    alt="offer"
                    src={offerTag}
                    className="offerTagMobile positionAbsolute"
                    style={{
                      right: "10px",
                      top: 0,
                      width: "60px"
                    }}
                  />
                )} */}
              </Col>

              <Col
                span={4}
                className="d-flex justify-content-center align-items-start"
              >
                {props.res.resOfferEnabled && (
                  <img
                    alt="offer"
                    src={offerTag}
                    className="offerTagMobile "
                    style={{
                      right: "10px",
                      top: 0,
                      width: "60px"
                    }}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {isMobile && <BottomFooter />}
    </>
  );
};
export default ProductCard;
