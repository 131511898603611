import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { rootAction } from "../../store/slices/rootSlices";
import {
  Row,
  Col,
  Card,
  Tag,
  Table,
  Descriptions,
  Skeleton,
  Divider,
  Tabs,
  Timeline
} from "antd";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { apiUrl, s3url, tDriveEndpoint } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";

// Import for Language translater
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import i18n from "./../../i18n";

export default function OrderHistory() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  let dispatch = useDispatch();
  let location = useLocation();
  let [isLoading, setStateLoading] = useState(true);
  let navigate = useNavigate();
  const user = useSelector((state) => state.userReducer);
  const [show, setShow] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [tflResponseData, setTflResponseData] = useState([]);
  const [tflHistoryCount, setTflHistoryCount] = useState(0);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [modalVar, setModalVar] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setModalVar(data);

    // Filter items that have a totalPrice
    let filteredPrice = data.items.filter((item) => item.totalPrice);

    // Sum the totalPrice of all filtered items
    let totalAmount = filteredPrice.reduce(
      (sum, item) => sum + item.totalPrice,
      0
    );
    setTotalProductPrice(totalAmount);
    console.log("data items", data.items);
    console.log("filteredPrice", filteredPrice);
    console.log("Total Price:", totalAmount); // Logs the total sum of all the totalPrice values
  };

  const columns = [
    {
      title: t("OrderItemName"),
      dataIndex: "name",
      key: "name",
      width: "10%"
    },
    {
      title: t("OrderQuantity"),
      dataIndex: "quantity",
      key: "quantity",
      width: "1%"
    },
    {
      title: t("OrderPrice"),
      dataIndex: "totalPrice",
      key: "totalPrice",
      width: "5%",
      render: (record, item) => {
        return "Kr. " + Number(item.price * item.quantity).toFixed(2);
      }
    }
  ];

  const proColumns = [
    {
      title: t("OrderProductsName"),
      dataIndex: "name",
      key: "name",
      width: "10%"
    },
    {
      title: t("OrderQuantity"),
      dataIndex: "quantity",
      key: "quantity",
      width: "1%"
    },
    {
      title: t("OrderPrice"),
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "5%",
      render: (record, item) => {
        return "Kr. " + Number(item.totalAmount * item.quantity).toFixed(2);
      }
    }
  ];
  useEffect(() => {
    const cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };

    const fetchData = async () => {
      setStateLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}${tDriveEndpoint}appuser/appuserallorders`,
          cardHistoryData
        );
        setResponseData(response.data.data.data);
        setHistoryCount(response.data.data.totalOrders);
      } catch (error) {
        console.error(
          "---error user---",
          error.response?.data?.message || error.message
        );
      } finally {
        setStateLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const tflHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };

    const fetchData = async () => {
      setStateLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}${tDriveEndpoint}appuser/tfluserallorders`,
          tflHistoryData
        );
        setTflResponseData(response.data.data.data);
        setTflHistoryCount(response.data.data.totalOrders);
      } catch (error) {
        console.error(
          "---error user---",
          error.response?.data?.message || error.message
        );
      } finally {
        setStateLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  const tflDesignCard = () => {
    return (
      <>
        {" "}
        {tflResponseData.map((data) => (
          <Col xs={24} lg={8} key={data._id} className="width-100">
            <Card className="historyCard width-100 border-radius-10">
              <Row
                id="cardHistory"
                className="justify-content-between align-items-center"
              >
                <Col span={24}>
                  <Row className="justify-content-between align-items-center">
                    <p className="mb-0">
                      <b>OTP:</b> {data?.otp}
                    </p>
                    <Tag
                      color={
                        data?.orderStatus === t("DeliveredText")
                          ? "darkgreen"
                          : data?.orderStatus === t("InProcessText")
                          ? "orange"
                          : data?.orderStatus === t("PendingText")
                          ? "grey"
                          : data?.orderStatus === t("CancelText")
                          ? "darkred"
                          : "#f50" // Default color
                      }
                    >
                      {data?.orderStatus}
                    </Tag>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={12}>
                      <p className="mb-0">
                        <b>{t("OrderDate")}:</b>{" "}
                        {new Date(data?.createdAt).toLocaleDateString()}
                      </p>
                    </Col>{" "}
                    <Col span={12} className="text-right">
                      <p className="mb-0">
                        <b>{t("OrderTime")}:</b>{" "}
                        {new Date(data?.createdAt).toLocaleTimeString()}
                      </p>{" "}
                    </Col>
                  </Row>

                  <Row>
                    <Divider />
                    <b>{t("CustomerDetails")}:</b>
                    <Col span={24}>
                      <p className="mb-0">
                        <b>{t("OrderName")}:</b> {data?.customerDetail?.name}
                      </p>
                    </Col>
                    <Col span={24}>
                      <p className="mb-0">
                        <b>{t("OrderEmail")}:</b> {data?.customerDetail?.email}
                      </p>
                    </Col>
                    <Col span={24}>
                      <p className="mb-0">
                        <b>{t("OrderMobileNumber")}:</b>{" "}
                        {data?.customerDetail?.phoneNo}
                      </p>
                    </Col>
                    <Col span={24}>
                      <p className="mb-0">
                        <b>{t("OrderAddress")}:</b>{" "}
                        {data?.customerDetail?.address}
                      </p>
                    </Col>
                  </Row>

                  <Divider />
                  <p className="mb-0">
                    <b>{t("ProductsDetail")}:</b>
                  </p>
                  <Table
                    bordered
                    columns={proColumns}
                    layout="vertical"
                    dataSource={data.items}
                  />
                  <Row className="justify-content-between align-items-center mt-2">
                    <p className="mb-0">
                      <b>{t("OrderTotal")}:</b>{" "}
                    </p>
                    <p className="mb-0">
                      <span>Kr. {data?.totalAmount}</span>
                    </p>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </>
    );
  };
  const foodDesignCard = () => {
    return (
      <>
        {responseData.map((data) => (
          <Col xs={24} lg={8} key={data._id} className="width-100 mb-2">
            <Card
              className="historyCard border-radius-10"
              actions={[
                <Button
                  variant="primary"
                  onClick={() => {
                    handleShow(data);
                    console.log("Data after click", data);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "0 0 10px 10px"
                  }}
                >
                  {t("OrderDetailsButton")}
                </Button>
              ]}
            >
              <Tag color="warning" className="width-100 text-center mb-2">
                {t("OrderType")} : {data?.orderType}
              </Tag>
              <Row className="justify-content-between align-items-center">
                <p className="mb-0">
                  <b>OTP:</b> {data?.otp}
                </p>
                <Tag
                  color={
                    data?.orderStatus === t("DeliveredText")
                      ? "darkgreen"
                      : data?.orderStatus === t("InProcessText")
                      ? "orange"
                      : data?.orderStatus === t("PendingText")
                      ? "grey"
                      : data?.orderStatus === t("CancelText")
                      ? "darkred"
                      : "#f50" // Default color
                  }
                >
                  {data?.orderStatus}
                </Tag>
              </Row>
              <Row>
                <Divider />
                <Col span={12} className="d-flex flex-column">
                  <p className="mb-0">
                    <b>{t("OrderDate")}:</b>{" "}
                    {new Date(data?.createdAt).toLocaleDateString()}
                  </p>
                  <p className="mb-0">
                    <b>{t("OrderTime")}:</b>{" "}
                    {new Date(data?.createdAt).toLocaleTimeString()}
                  </p>{" "}
                  <p className="mb-0">
                    <b>{t("OrderItemNumber")}:</b>
                    <span>{data?.items[0]?.quantity}</span>
                  </p>
                </Col>
                <Col span={12} className="d-flex flex-column text-right">
                  <p className="mb-0">
                    <b>{t("OrderRestaurantDetails")}</b>
                  </p>
                  {/* <p className="mb-0"> {data?.branchId?.name}</p> */}
                  <p className="mb-0">
                    {data?.branchId?.name
                      ? data.branchId.name.split(" ").length > 5
                        ? data.branchId.name.split(" ").slice(0, 4).join(" ") +
                          "..."
                        : data.branchId.name
                      : ""}
                  </p>
                  <p className="mb-0"> {data?.branchId?.phoneNo}</p>
                </Col>
              </Row>
              {data?.orderStatus === "IN-PROCESS" &&
                data?.orderType === "Drive Out" && (
                  <Row
                    id="cardHistory"
                    className="justify-content-between align-items-center"
                  >
                    <Col span={24}>
                      <Divider />
                      <p>
                        {t("OrderDriver")}
                        <span>
                          {data?.driverId?.firstName} {data?.driverId?.lastName}
                        </span>
                      </p>
                      <p>
                        {t("OrderDriverNumber")}
                        <span>{data?.driverId?.phoneNumber}</span>
                      </p>
                    </Col>
                  </Row>
                )}

              <Divider />
              <Row className="align-items-center">
                <p className="mb-0">
                  <b>{t("OrderTotal")}:</b>
                </p>{" "}
                &nbsp;
                <p className="mb-0">
                  <span>Kr. {data?.totalPrice}</span>{" "}
                </p>
              </Row>
            </Card>
          </Col>
        ))}
      </>
    );
  };
  return (
    <>
      {isMobile ? (
        <Tabs className="orderHistory" defaultActiveKey="item-1">
          <Tabs.TabPane tab={t("OrderFoodHistory")} key="item-1">
            <Row style={{ marginTop: "64px" }} className="px-3 pt-3">
              {isLoading ? (
                <div className="skeltonTopMargin width-100 px-3">
                  <Row>
                    <Col xs={24} className="width-100">
                      <Skeleton avatar paragraph={{ rows: 5 }} />
                    </Col>
                    <Col xs={24} className="width-100">
                      <Skeleton avatar paragraph={{ rows: 5 }} />
                    </Col>
                  </Row>
                </div>
              ) : (
                <>
                  {historyCount > 0 ? (
                    <div className="d-flex flex-column width-100">
                      <Row className="mb-5 width-100">{foodDesignCard()}</Row>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                      <p className="text-dark font-18 font-700">
                        {t("OrderNoYet")}
                      </p>
                      <a
                        className="aboutNavLink greenAccentBg border-radius-10 font-900"
                        style={{ cursor: "pointer", padding: "8px 50px" }}
                        onClick={() => {
                          dispatch(rootAction.setOrderType("Drive Out"));
                          navigate("/restaurants");
                        }}
                      >
                        {t("OrderButton")}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("OrderProductHistory")} key="item-2">
            <Row style={{ marginTop: "64px" }} className="px-3 pt-3">
              {isLoading ? (
                <div className="skeltonTopMargin width-100 px-3">
                  <Row>
                    <Col xs={24} sm={24} lg={8} className="width-100">
                      <Skeleton avatar paragraph={{ rows: 5 }} />
                    </Col>
                    <Col xs={24} sm={24} lg={8} className="width-100">
                      <Skeleton avatar paragraph={{ rows: 5 }} />
                    </Col>
                  </Row>
                </div>
              ) : (
                <>
                  {tflHistoryCount > 0 ? (
                    <div className="d-flex flex-column width-100">
                      <Row gutter={[16, 16]} className="mb-5">
                        {tflDesignCard()}
                      </Row>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                      <p className="text-dark font-18 font-700">
                        {t("OrderNoYet")}
                      </p>
                      <a
                        className="aboutNavLink greenAccentBg border-radius-10 font-900"
                        style={{
                          cursor: "pointer",
                          padding: "8px 50px"
                        }}
                        onClick={() => {
                          navigate("/tflMarket");
                        }}
                      >
                        {t("OrderButton")}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <div style={{ marginTop: "60px" }}>
          <Tabs className="orderHistory" defaultActiveKey="item-1">
            <Tabs.TabPane tab={t("OrderFoodHistory")} key="item-1">
              <Row style={{ marginTop: "64px" }} className="px-3 pt-3">
                {isLoading ? (
                  <div className="skeltonTopMargin width-100 px-3">
                    <Row>
                      <Col xs={24} sm={24} lg={8} className="width-100">
                        <Skeleton avatar paragraph={{ rows: 5 }} />
                      </Col>
                      <Col xs={24} sm={24} lg={8} className="width-100">
                        <Skeleton avatar paragraph={{ rows: 5 }} />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <>
                    {historyCount > 0 ? (
                      <div className="d-flex flex-column width-100">
                        <Row gutter={[16, 16]} className="mb-5 px-3">
                          {foodDesignCard()}
                        </Row>
                      </div>
                    ) : (
                      <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                        <p className="text-dark font-18 font-700">
                          {t("OrderNoYet")}
                        </p>
                        <a
                          className="aboutNavLink greenAccentBg border-radius-10 font-900"
                          style={{
                            cursor: "pointer",
                            padding: "8px 50px"
                          }}
                          onClick={() => {
                            dispatch(rootAction.setOrderType("Drive Out"));
                            navigate("/restaurants");
                          }}
                        >
                          {t("OrderButton")}
                        </a>
                      </div>
                    )}
                  </>
                )}
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("OrderProductHistory")} key="item-2">
              <Row style={{ marginTop: "64px" }} className="px-3 pt-3">
                {isLoading ? (
                  <div className="skeltonTopMargin width-100 px-3">
                    <Row>
                      <Col xs={24} sm={24} lg={6} className="width-100">
                        <Skeleton avatar paragraph={{ rows: 5 }} />
                      </Col>
                      <Col xs={24} sm={24} lg={6} className="width-100">
                        <Skeleton avatar paragraph={{ rows: 5 }} />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <>
                    {tflHistoryCount > 0 ? (
                      <div className="d-flex flex-column width-100">
                        <Row gutter={[16, 16]} className="mb-5 px-3">
                          {tflDesignCard()}
                        </Row>
                      </div>
                    ) : (
                      <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                        <p className="text-dark font-18 font-700">
                          {t("OrderNoYet")}
                        </p>
                        <a
                          className="aboutNavLink greenAccentBg border-radius-10 font-900"
                          style={{
                            cursor: "pointer",
                            padding: "8px 50px"
                          }}
                          onClick={() => {
                            navigate("/tflMarket");
                          }}
                        >
                          {t("OrderButton")}
                        </a>
                      </div>
                    )}
                  </>
                )}
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
      <Modal show={show} onHide={handleClose} className="modalHistory">
        <Modal.Header closeButton>
          <Modal.Title className="font-16 d-flex">
            <img
              alt="products"
              src={`${s3url}/${modalVar?.branchId?.logo}`}
              style={{ borderRadius: "10px", width: "50px" }}
            />
            <span
              style={{ padding: "0 10px" }}
              className="d-flex align-items-center"
            >
              {" "}
              {modalVar?.branchId?.name}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col span={12}>OTP: {modalVar?.otp}</Col>
            <Col span={12} className="text-right">
              <Tag
                color={
                  modalVar?.orderStatus === t("DeliveredText")
                    ? "darkgreen"
                    : modalVar?.orderStatus === t("InProcessText")
                    ? "orange"
                    : modalVar?.orderStatus === t("PendingText")
                    ? "grey"
                    : modalVar?.orderStatus === t("CancelText")
                    ? "darkred"
                    : "#f50" // Default color
                }
              >
                {modalVar?.orderStatus}
              </Tag>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            layout="vertical"
            dataSource={modalVar?.items}
          />

          <Row className="mt-3">
            <Col span={12}>
              <p className="font-700">{t("OrderProduct")}</p>
            </Col>
            <Col span={12}>
              <p className="text-right">
                {(
                  totalProductPrice -
                  (totalProductPrice - (100 * totalProductPrice) / (100 + 15))
                ).toFixed(2)}{" "}
                Kr
              </p>
            </Col>
            <Col span={12}>
              <p className="font-700">{t("OrderTax")}</p>
            </Col>
            <Col span={12}>
              <p className="text-right">
                {(
                  totalProductPrice -
                  (100 * totalProductPrice) / (100 + 15)
                ).toFixed(2)}{" "}
                Kr
              </p>
            </Col>

            {modalVar?.tdServiceCharge > 0 && (
              <>
                <Col span={12}>
                  <p>
                    <b>{t("OrderFee")}</b>
                  </p>
                </Col>
                <Col span={12} className="text-right">
                  <p>{modalVar?.tdServiceCharge / 1.25} Kr</p>
                </Col>
                <Col span={12}>
                  <p>
                    <b>{t("OrderVat")}</b>
                  </p>
                </Col>
                <Col span={12} className="text-right">
                  <p>{(modalVar?.tdServiceCharge / 1.25) * 0.25} Kr</p>
                </Col>
              </>
            )}
            {modalVar?.tdDriveOutCharge > 0 && (
              <>
                <Col span={12}>
                  <p className="">
                    <b>{t("OrderCharge")}</b>
                  </p>
                </Col>
                <Col span={12}>
                  <p className=" text-right">
                    {(modalVar?.tdDriveOutCharge / 1.25).toFixed(2)} Kr
                  </p>
                </Col>

                <Col span={12}>
                  <p className="">
                    <b>{t("OrderDeliveryVat")}</b>
                  </p>
                </Col>
                <Col span={12}>
                  <p className=" text-right">
                    {((modalVar?.tdDriveOutCharge / 1.25) * 0.25).toFixed(2)} Kr
                  </p>
                </Col>
              </>
            )}
            {modalVar?.discount != 0 && (
              <>
                {" "}
                <Col span={12}>
                  <p>
                    <b>{t("OrderDiscount")}</b>
                  </p>
                </Col>
                <Col span={12} className="text-right">
                  <p> {modalVar?.discount} Kr</p>
                </Col>{" "}
              </>
            )}
            <Divider />
            <Col span={12}>
              <p className="font-16 mb-0">
                <b>{t("OrderAmount")}</b>
              </p>
            </Col>
            <Col span={12} className="text-right">
              <p className="font-16 mb-0"> {modalVar?.totalPrice} Kr</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{ width: "100%" }}
          >
            {t("OkButton")}
          </Button>
        </Modal.Footer>
      </Modal>
      <BottomFooter />
    </>
  );
}
