import React, { useState, useRef, useEffect } from "react";

import { Button, Modal, Card } from "react-bootstrap";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import i18n from "../../i18n";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  saveLocationAndLatlng,
  getRestaurants
} from "../../store/actions/locationaction";

import { distanceAction } from "../../store/slices/distanceSlices";

import "mapbox-gl/dist/mapbox-gl.css";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken =
  "pk.eyJ1Ijoib20tZGF0YSIsImEiOiJjbGY2cXF3YXQwbmJoM3NubnRhYW5mbXpwIn0.qeCkkjp_WXmXrekPzF7wrQ";

function LocationModal(props) {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations
  let dispatch = useDispatch();

  let root = useSelector((state) => state.rootReducer);

  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );
  // let retailer = useSelector((state) => state.retailerReducer.selectedRetailer);
  let address = useSelector((state) => state.locationReducer);

  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(address.longitude);
  const [lat, setLat] = useState(address.latitude);

  const [zoom, setZoom] = useState(11);

  const [addressPlace, setaddressPlace] = useState("");
  const [confirmAddress, setConfirmAddress] = useState("");

  const [show, setShow] = useState(true);

  fetch(`${mapboxgl.accessToken}`);

  const handleClose = () => {
    setShow(false);
    props.onShowCancelModal();
  };

  useEffect(() => {
    if (lat == "" || lng == "") {
      // console.log("---Getting user location---");

      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log("----Init pos----", position);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        });
      } else {
        message.info(t("GiveLocAccess"));
        // alert("Please allow EzyGo to access your location");
      }
    }
  }, []);

  useEffect(() => {
    if (map.current) return;

    // console.log("--Current longitude & latitude---", lng, lat);
    // console.log(
    //   "--Current lat lng in location slice---",
    //   address.latitude,
    //   address.longitude
    // );

    setLat(address.latitude);
    setLng(address.longitude);

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [address.longitude, address.latitude],
      zoom: zoom
    });

    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    });

    map.current.addControl(geolocate);

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      marker: false,
      mapboxgl: mapboxgl
    });
    map.current.addControl(geocoder);
    getMapAddress();
  }, []);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    var marker = new mapboxgl.Marker({
      color: "green"
    })
      .setLngLat([lat, lng])
      .addTo(map.current);

    marker.setLngLat(map.current.getCenter());

    map.current.on("moveend", function (e) {
      let langLat = map.current.getCenter();
      marker.setLngLat(langLat);
      setLat(langLat.lat);
      setLng(langLat.lng);
    });

    map.current.on("click", function (e) {
      let langLat = e.lngLat;

      map.current.flyTo({
        center: langLat
      });

      setLat(langLat.lat);
      setLng(langLat.lng);
    });
  });

  useEffect(() => {
    getMapAddress();
  }, [lat]);

  useEffect(() => {
    if (restaurant) {
      // console.log("---Restaurant selected now setting distance---");
      setDistance(restaurant.resLongitude, restaurant.resLatitude);
    }
  }, [restaurant]);

  const getMapAddress = async () => {
    // console.log("---Getting Address--", lng, lat);
    try {
      let response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?&access_token=pk.eyJ1Ijoib20tZGF0YSIsImEiOiJjbGY2cXF3YXQwbmJoM3NubnRhYW5mbXpwIn0.qeCkkjp_WXmXrekPzF7wrQ`
      );
      // console.log(`Current Location:`, response.data.features[0].place_name);

      setConfirmAddress(response.data.features[0].place_name);

      dispatch(
        saveLocationAndLatlng(
          response.data.features[0].place_name,
          lat,
          lng,
          ""
        )
      );

      // console.log("---Restaurant data---", restaurant);

      if (restaurant) {
        setDistance(restaurant.resLongitude, restaurant.resLatitude);
      }
    } catch (error) {
      // console.log("---Error in getting Address----", error);
    }
  };

  const setDistance = async (originlng, originlat) => {
    if (restaurant) {
      try {
        // console.log("--Calculating distance----");

        let distance = 0;
        let address = "";

        let result = await axios.get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${originlng},${originlat};${lng},${lat}?alternatives=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=pk.eyJ1Ijoib20tZGF0YSIsImEiOiJjbGY2cXF3YXQwbmJoM3NubnRhYW5mbXpwIn0.qeCkkjp_WXmXrekPzF7wrQ`
        );
        if (result.status == 200) {
          if (result.data.routes && result.data.routes.length) {
            if (result.data.routes[0].distance) {
              let distanceBetweenTwoPoint = result.data.routes[0].distance;
              distance = Number((distanceBetweenTwoPoint / 1000).toFixed(2));
              address = result.data.waypoints[1].name;

              // console.log("--Setting Distance---", distance, address);

              let dist = {
                resLat: originlat,
                cusLat: lat,
                resLng: originlng,
                cusLng: lng,
                disRestToCus: distance
              };

              dispatch(distanceAction.saveDistance(dist));
            }
          }
        } else {
          // console.log("--Error in getting distance---");
        }
      } catch (error) {
        // console.log(error, "error in calculating distance");
        return error;
      }
    }
  };

  let onConfirmAddressHandler = () => {
    if (lat && lng) {
      dispatch(getRestaurants({ lat: lat, lng: lng }, root.orderType));
      // console.log("clicked button", root.orderType);
    }

    setShow(false);
    props.onShowCancelModal();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      //  size="lg"
    >
      <Modal.Header closeButton className="mainBgColor text-white">
        <Modal.Title>{t("LocationConfirmButton")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <div ref={mapContainer} id="map" className="mapContainer"></div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onConfirmAddressHandler}
          style={{ width: "100%" }}
        >
          {t("MapFormVaerifyButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LocationModal;
