import { Button } from "antd";
// Import for Language translater
import { useTranslation } from 'react-i18next';
const Footer = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations
  return (
    <>
      {!props.isEnablePay && (
        <Button
          type="primary"
          className="saveBtn w-100 mt-4"
          disabled={!props.formValid}
          onClick={props.onAddCustomer}
        >
          {t("FooterSaveProceed")}
        </Button>
      )}
    </>
  );
};

export default Footer;
