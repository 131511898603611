import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { LeftCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import BottomFooter from "../Footer/BottomFooter";
import { useTranslation } from "react-i18next";

export default function InviteCommon() {
  const { t, i18n } = useTranslation(); // Hook to access translations
  const location = useLocation();
  const key = location.state?.key;
  console.log("keyyyyyyyyyyy", key);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const content = () => {
    return (
      <>
        <p className="font-16 font-700">
          {t("InviteCommonQuestion")}&nbsp;
          {key == "food_and_drink" ? "restaurant" : "salong"}?{" "}
        </p>
        <p className="font-16 font-700">{t("InviteCommonLine1")}</p>
        <p className="font-16 font-700">{t("InviteCommonLine2")}</p>
        <p className="font-16 font-700">
          {t("InviteCommonLine3Start")}{" "}
          {key == "food_and_drink" ? "restaurant" : "salong"}&nbsp;{" "}
          {t("InviteCommonLine3End")}
        </p>
        <ul className="text-dark p-0" style={{ listStyle: "none" }}>
          <li>✅ {t("InviteCommonPoint1")}</li>
          <li>✅ {t("InviteCommonPoint2")}</li>
          <li>✅ {t("InviteCommonPoint3")}</li>
          <li>✅ {t("InviteCommonPoint4")} </li>
        </ul>{" "}
        <Row gutter={[16, 16]}>
          {key == "food_and_drink" ? (
            <Col xs={24} lg={24}>
              {" "}
              <button
                className="mainBgColor border-radius-5 text-white p-2 mt-2 width-100"
                onClick={() =>
                  navigate("/inviteList", {
                    state: { key: "food_and_drink" }
                  })
                }
                style={{
                  cursor: "pointer",
                  border: "none",
                  transition: "all 0.3s ease"
                }}
              >
                {t("InviteCommonRestBtn")}
              </button>
            </Col>
          ) : (
            <Col xs={24} lg={24}>
              {" "}
              <button
                className="mainBgColor border-radius-5 text-white p-2 mt-2 width-100"
                onClick={() =>
                  navigate("/inviteList", {
                    state: { key: "salon" }
                  })
                }
                style={{
                  cursor: "pointer",
                  border: "none",
                  transition: "all 0.3s ease"
                }}
              >
                {t("InviteCommonSalonBtn")}
              </button>
            </Col>
          )}
        </Row>{" "}
        <br />
        <p>*{t("InviteCommonLastLine")}</p>
      </>
    );
  };
  return (
    <>
      <div
        className={
          isMobile ? "vh90andScroll mb-5 pb-5 px-3" : "mission profilePages"
        }
        style={{ marginTop: isMobile ? "" : "100px" }}
      >
        {isMobile && (
          <Row className="justify-content-between mt-2 mb-3">
            <h3 className="mb-0 font-700 font-24">
              {t("Invite")} {key == "food_and_drink" ? "Restaurants" : "Salons"}
            </h3>
            <LeftCircleOutlined
              onClick={() => window.history.back()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px"
              }}
            />
          </Row>
        )}
        {isMobile ? (
          <div
            className="commonShadowAndRound p-4"
            style={{ background: "#fff" }}
          >
            {content()}
          </div>
        ) : (
          <Row className="align-items-center justify-content-center">
            <Col lg={8} className="commonShadowAndRound p-5">
              {content()}
            </Col>
          </Row>
        )}
      </div>
      <BottomFooter />
    </>
  );
}
