import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Row, Col, Button } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import friends from "../../assets/friends.webp";
import BottomFooter from "../Footer/BottomFooter";
import { ezyGoWebLinkPoint } from "../../helper/endpoint";

// Import for Language translater
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import i18n from "./../../i18n";

export default function ShareReferral() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  let navigate = useNavigate();
  let user = useSelector((state) => state.userReducer);
  const userReferralCode = user.userReferralCode || "";

  console.log("user", user);

  const handleShare = async () => {
    const referralUrl = `${ezyGoWebLinkPoint}signup?ref=${userReferralCode}`;
    const message = `Klikk for å søke👉: *${referralUrl}*

    🚀 Jeg bruker EzyGo! Smarteste løsningen for mat, salong og produktbestilling i Norge.
    ✨ Få eksklusive tilbud, belønninger og en smidig bestillingsordning.
    🎁 Bruk min henvisningskode *${userReferralCode}* når du registrerer deg og tjen TFL-coins!

    *Registrer deg direkte her: *
    📲 *[App Store](https://apps.apple.com/in/app/ai-ezygo/id6444897318)*

    📲 *[Google Play](https://play.google.com/store/apps/details?id=com.omvadata.treedriveuser&hl=en_IN)*

Klikk for å søke👉:`;

    try {
      await navigator.clipboard.writeText(message);

      if (navigator.share) {
        await navigator.share({
          title: "Henvisningskode",
          text: message,
          url: referralUrl
        });
      } else {
        const subject = encodeURIComponent(
          "Bli med i EzyGo med min henvisningskode!"
        );
        const body = encodeURIComponent(message);
        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.open(mailtoLink, "_blank");
      }
    } catch (error) {
      console.error("Feil under deling av henvisningskode:", error);
    }
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, [user, navigate]);

  const content = () => (
    <Row className="p-3 justify-content-center">
      <Col span={24}>
        <h3 className="font-24 font-700 mt-3" style={{ color: "#0066CC " }}>
          {t("ShareInviteFriendsTitle")}
        </h3>
        <p>
          {t("ShareInviteFriendsText")}
        </p>
      </Col>
      <Col
        sm={24}
        lg={8}
        className="d-flex align-items-center justify-content-center"
      >
        <img src={friends} loading="lazy" alt="Invite Friends" width={"100%"} />
      </Col>
      <Col span={24}>
        <Row
          style={{ background: "#0066CC" }}
          className="border-radius-10 p-3 text-white justify-content-between align-items-center"
        >
          <Col>
            <h3 className="font-24 text-white mb-1">{userReferralCode}</h3>
            <p className="mb-0">
              {t("ShareRefferalCode")}
            </p>
          </Col>
          <Col>
            <Button
              type="text"
              shape="circle"
              icon={
                <ShareAltOutlined style={{ color: "#fff", fontSize: "60px" }} />
              }
              onClick={handleShare}
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} className="mt-3">
        <p>
          <b>
            <i>
              {t("ShareReferEarn")}
            </i>
          </b>
        </p>
        <p>
          <Trans i18nKey={"ShareFriends"} common={"br : <br/>"} />
        </p>
      </Col>
    </Row>
  );

  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll">{content()}</Row>
      ) : (
        <div style={{ marginTop: "64px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="commonShadowAndRound my-5 bg-white">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
