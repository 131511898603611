import React from "react";
import BottomFooter from "../Footer/BottomFooter";

export default function UnderConstruction() {
  return (
    <div>
      <div className="agentPage"></div>
      <BottomFooter />
    </div>
  );
}
