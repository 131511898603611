import React from "react";
import ConfirmPorduct from "./SuperCategory/ProductCategory/Product/ConfirmPorduct";

// Import for Language translater
import { useTranslation } from 'react-i18next';
const OrderNowButon = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  return (
    <ConfirmPorduct
      link={t("OrderButtonNow")}
      isMobile={props.isMobile}
      onCloseMobileProductModal={props.onCloseMobileProductModal}
      handleCancelModal={props.handleCancelModal}
      ing={props.ing}
      singleProduct={props.singleProduct}
    />
  );
};
export default OrderNowButon;
