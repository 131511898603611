import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import MobileNavBar from "../NavbarHeader/MobileNavBar";
import { isMobile } from "react-device-detect";
import ezygoSplashScreenLogo from "../../assets/ezygoSplashScreenLogo.gif";

const EzygoIndex = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
    }

    if (isMobile) {
      const hasShownSplash = sessionStorage.getItem("hasSeenSplash");

      if (!hasShownSplash) {
        setLoading(true);
        sessionStorage.setItem("hasSeenSplash", "true");

        setTimeout(() => {
          console.log("Splash Timer Done!");
          setLoading(false);
        }, 3000);
      }
    }
  }, [location.pathname, navigate]);


  if (loading) {
    return (
      <div style={styles.splashContainer}>
        <img src={ezygoSplashScreenLogo} width={100} />
      </div>
    );
  }

  return (
    <>
      {!isMobile && <MobileNavBar />}
      <Outlet />
    </>
  );
};

const styles = {
  splashContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f0f2f5"
  }
};

export default EzygoIndex;
