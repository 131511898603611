import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";
import { Row, Col, Divider } from "antd";
import { FloatingLabel, Button, Form, Alert } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import circleLogin from "../../assets/circleLogin.webp";
import pinLogin from "../../assets/pinLogin.webp";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";

// Import for Language translater
import { useTranslation } from "react-i18next";
export default function Verify() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const dispatch = useDispatch();

  let responseError = useSelector((state) => state.errorReducer.errorMessage);

  let [alertMsg, setAlertMsg] = useState("");
  let [alertType, setAlertType] = useState("");
  let [showAlert, setShowAlert] = useState(false);
  let [phoneNumber, setPhoneNumber] = useState(""); // State for phone number

  // ✅ LocalStorage se verifyMobile ko load karo
  useEffect(() => {
    const storedMobile = localStorage.getItem("verifyMobile");
    if (storedMobile) {
      setPhoneNumber(storedMobile); // ✅ Phone number ko set karo
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let otp = e.target.inputOtp.value;

    if (otp === "" || phoneNumber === "") {
      setShowAlert(true);
      setAlertType("danger");
      setAlertMsg(t("VerifyMobileNumber"));
    } else {
      setShowAlert(true);
      setAlertType("warning");
      setAlertMsg(t("VerifyPleaseWait"));

      //try{

      let formData = {
        otp: otp,
        phone: phoneNumber
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/otpverifcation`, formData)
        .then((response) => {
          if (response.data.status === 200) {
            setShowAlert(true);
            setAlertType("success");
            setAlertMsg(response.data.message);
            localStorage.removeItem("verifyMobile");

            window.location = "login";
          } else {
            setShowAlert(true);
            setAlertType("danger");
            setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          setShowAlert(true);
          setAlertType("danger");
          setAlertMsg(error.response?.data?.message || "Error occurred.");
        });
    }
  };

  const commonVerify = () => {
    return (
      <>
        <Row
          className="d-flex flex-column align-items-center justify-content-center logBox"
          style={{ borderRadius: "20px" }}
        >
          <Col span={24} className="text-center width-100">
            {" "}
            <h4 class="font-18 font-700">{t("EzyGo")}</h4>
            <h4
            className="font-18 font-700 mt-1 d-flex align-items-center justify-content-center"
              style={{ color: "#0066CC " }}
            >
              <img src={circleLogin} loading="lazy" />
              &nbsp; {t("LoginSimplified")}!&nbsp;
              <img src={pinLogin} loading="lazy" />
            </h4>
            <Divider>{t("VerifyAccount")}</Divider>
          </Col>
          <Col span={24} className="text-center width-100">
            <Form noValidate onSubmit={handleSubmit}>
            {/* ✅ Mobile Number Field */}
            <Form.Group className="mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label={t("SignupMobilePLaceholder")}
                  >
                    <Form.Control
                      required
                      type="number"
                      placeholder={t("MobilePLaceholder")}
                      name="inputPhone"
                      inputMode="numeric"
                      pattern="[0-9]*"
                    />
                  </FloatingLabel>
                </Col>
              </Form.Group>

            {/* ✅ OTP Field */}
            <Form.Group className="mb-3">
                <Col md>
                  <FloatingLabel controlId="floatingInputGrid" label="OTP*">
                    <Form.Control
                      required
                      type="number"
                      placeholder="OTP"
                      name="inputOtp"
                    />
                  </FloatingLabel>
                  <p className="mb-4 pt-2 text-left">{t("VerifyEnterCode")}</p>
                </Col>
              </Form.Group>

              {showAlert && <Alert variant={alertType}>{alertMsg}</Alert>}

              <Button
                variant="primary"
                type="submit"
                className="width-100 py-2 border-radius-10"
              >
                {t("VerifyAccountButton")}
              </Button>
            </Form>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {!isMobile && <PrivacyHeader />}
      {!isMobile && (
        <section className="mission profilePages p-5">
          <div className="d-flex justify-content-center py-5 mt-5 ">
            <Col s md={9} xl={6} className="pb-3">
              {commonVerify()}
            </Col>
          </div>
        </section>
      )}
      {isMobile && (
        <section
          className="verifyBg p-2 d-flex align-items-center justify-content-center"
          style={{ height: "95vh" }}
        >
          {commonVerify()}
        </section>
      )}
      <BottomFooter />
    </>
  );
}
