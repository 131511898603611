import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { Card, Avatar, Row, Col, DatePicker, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import { userAction } from "../../store/slices/userslices";
import { s3url, apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";
import profileIcon from "../../assets/profileFallBack.webp";
import moment from "moment";

// Import for Language translater
import { useTranslation } from "react-i18next";

export default function EditProfile() {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  let user = useSelector((state) => state.userReducer);
  let userprofileImage = user.profileImage;
  const token = user.token;
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    dateOfBirth: user?.dateOfBirth || "",
    gender: user?.gender || ""
  });

  const profileImageSrc =
    userprofileImage == "" ? profileIcon : `${s3url}/${userprofileImage}`;

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        dateOfBirth: user.dateOfBirth || "",
        gender: user.gender || ""
      });
    }
    if (!user || !user.token) {
      navigate("/login");
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.firstName || !formData.lastName) {
      message.warning(t("AllMandatoryFields"));
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/updateuser`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        }
      );

      if (response.data.status === 200) {
        message.success(t("ProfileIsUpdated"));
        dispatch(userAction.saveUser({ ...user, ...response.data.data }));
      } else {
        message.warning(response.data.message);
      }
    } catch (error) {
      message.error(t("SomeThingWentWrong"));
    }
  };
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      message.warning(t("NoFileIsSelected"));
      return;
    }

    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      message.warning(t("FileShouldNotExceed5Mb"));
      return;
    }

    const formData = new FormData();
    formData.append("profileImage", file);

    try {
      setLoading(true);
      const response = await fetch(
        `${apiUrl}${tDriveEndpoint}appuser/updateuser`,
        {
          method: "POST",
          body: formData,
          headers: {
            "x-auth-token": token
          }
        }
      );

      const data = await response.json();
      console.log("---response server---", data);
      if (data.status === 200) {
        message.success(t("ProfileImageUpdated"));
        dispatch(
          userAction.saveUser({
            ...user,
            profileImage: data.data.profileImage
          })
        );
      }
      if (!response.ok) {
        throw new Error(
          `${response.status}: ${data.message || t("ImageUploadFailed")}`
        );
      }
      console.log("Image uploaded successfully:", data);
    } catch (error) {
      console.error("Error uploading image:", error.message);
      alert("Failed to upload image: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  const content = () => {
    return (
      <Card className="border-radius-10">
        <Row className="justify-content-between">
          <h3 className="font-24 font-700 mb-0">{t("ShortCutEditProfile")}</h3>
          <LeftCircleOutlined
            onClick={() => window.history.back()}
            style={{ fontSize: "30px", cursor: "pointer" }}
          />
        </Row>
        <Row className="justify-content-center pb-5">
          <Col span={24} className="d-flex flex-column align-items-center">
            {" "}
            <br />
            <div
              style={{
                position: "relative",
                display: "inline-block",
                cursor: "pointer"
              }}
              onClick={() => document.getElementById("fileUpload").click()}
            >
              <Avatar
                size={100}
                src={profileImageSrc}
                style={{ marginBottom: "10px", border: "1px solid #999" }}
              />
              <EditOutlined
                style={{
                  position: "absolute",
                  bottom: "5px",
                  right: "5px",
                  background: "white",
                  borderRadius: "50%",
                  padding: "5px",
                  fontSize: "18px",
                  cursor: "pointer",
                  boxShadow: "0px 0px 5px rgba(0,0,0,0.2)"
                }}
              />
            </div>
            <input
              type="file"
              id="fileUpload"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            {loading && <div className="spinner">Laster opp...</div>}
            <p className="font-18 mt-2">{`${user.firstName} ${user.lastName}`}</p>
          </Col>
          <Form noValidate onSubmit={handleSubmit} className="width-100 mb-5">
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                {" "}
                <FloatingLabel
                  controlId="floatingFirstName"
                  label={t("SignupFirstName")}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("SignupFirstName")}
                    value={formData.firstName}
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={24}>
                <FloatingLabel
                  controlId="floatingLastName"
                  label={t("SignupLastName")}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("SignupLastName")}
                    value={formData.lastName}
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={24}>
                {" "}
                <FloatingLabel
                  controlId="floatingDob"
                  label={t("EditDateBirth")}
                >
                  <DatePicker
                    style={{
                      width: "100%",
                      fontSize: "18px",
                      padding: "40px 12px 10px"
                    }}
                    className="width-100"
                    placeholder={t("EditDateBirth")}
                    value={
                      formData.dateOfBirth ? moment(formData.dateOfBirth) : null
                    }
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        dateOfBirth: date ? date.format("YYYY-MM-DD") : ""
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col xs={24}>
                {" "}
                <FloatingLabel
                  controlId="floatingGender"
                  label={t("EditGender")}
                >
                  <Form.Select
                    value={formData.gender}
                    onChange={(e) =>
                      setFormData({ ...formData, gender: e.target.value })
                    }
                  >
                    <option value="">{t("ChooseGender")}</option>
                    <option value="male">{t("SexMale")}</option>
                    <option value="female">{t("SexFemale")}</option>
                    <option value="other">{t("SexOther")}</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs={24}>
                <FloatingLabel
                  controlId="floatingPhone"
                  label={t("SignupMobilePLaceholder")}
                >
                  <Form.Control type="number" disabled value={user.phone} />
                </FloatingLabel>
              </Col>
              <Col xs={24}>
                {" "}
                <FloatingLabel
                  controlId="floatingEmail"
                  label={t("SignupEmail")}
                >
                  <Form.Control type="email" disabled value={user.email} />
                </FloatingLabel>
              </Col>
              <Col span={24}>
                {" "}
                <Button variant="primary" type="submit" className="width-100">
                  {t("UpdateProfile")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Card>
    );
  };

  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll">{content()}</Row>
      ) : (
        <div style={{ marginTop: "70px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col span={8} className="commonShadowAndRound my-5">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
