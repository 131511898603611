import { Layout } from "antd";
import ContentComponent from "./Content";
import HeaderLayout from "./Header";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LocationModal from "../../containers/Modal/LocationModal";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import NotificationMessage from "../../helper/NotificationMessage";
import { errorAction } from "../../store/slices/errorSlices";
// Import for Language translater
import { useTranslation } from 'react-i18next';

import { Trans } from "react-i18next";
import i18n from "./../../i18n";

const TopBar = () => {
  //For Language Translater
     const { t, i18n } = useTranslation(); // Hook to access translations
  let errorState = useSelector((state) => state.errorReducer);
  let navigate = useNavigate();
  let location = useLocation();
  let [showLocationModal, setShowLocationModal] = useState(false);

  let onChangeLocationHandler = () => {
    setShowLocationModal(true);
  };

  useEffect(() => {
    if (errorState.isError) {
      setTimeout(() => {
        errorAction.clearError();
      }, 2000);
    }
  }, [errorState]);

  useEffect(() => {
    if (location.pathname == "/") {
      return navigate("/restaurants");
    }
  }, []);
  let onShowCancelModal = () => {
    setShowLocationModal(false);
  };
  return (
    <>
      {errorState.isError && (
        <NotificationMessage type="Error" message={t("SomethingWrong")} />
      )}

      <Layout>
        <HeaderLayout
          onChangeLocationHandler={onChangeLocationHandler}
        ></HeaderLayout>
        <ContentComponent></ContentComponent>
      </Layout>
      {showLocationModal && (
        <LocationModal onShowCancelModal={onShowCancelModal}></LocationModal>
      )}
    </>
  );
};

export default TopBar;
