import React, { useState } from "react";
import { Modal, Row, Col, Button } from "antd";

import ConfirmOrder from "../../components/Cart/ConfirmOrder";
const ConfirmOrderModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(true);


  const handleOk = () => {
    props.onCancelConfrimModal();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    props.onCancelConfrimModal();
    setIsModalVisible(false);
  };
  return (
    <Modal
      title="Bekreft ordre"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="commonShadowAndRound modal-bill"
    >
      <ConfirmOrder handleCancel={handleCancel} />
    </Modal>
  );
};

export default ConfirmOrderModal;
