import React, { useEffect } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { restaurantAction } from "../../store/slices/restaurantslices";
import { distanceAction } from "../../store/slices/distanceSlices";

import ProductCard from "./ProductCard";
import { isMobile } from "react-device-detect";
import Spinner from "react-bootstrap/Spinner";
import { rootAction } from "../../store/slices/rootSlices";

import { reserveTableurl } from "../../helper/endpoint";
// Import for Language translater
import { useTranslation } from "react-i18next";
const Cards = (props) => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  const user = useSelector((state) => state.userReducer);
  const userId = user._id;
  let root = useSelector((state) => state.rootReducer);
  let navigate = useNavigate();
  useEffect(() => {}, [props.restaurant]);
  let dispatch = useDispatch();
  let address = useSelector((state) => state.locationReducer);

  const setDistance = async (originlng, originlat) => {
    if (props.restaurant) {
      try {
        let lng = address.longitude;
        let lat = address.latitude;
        let distance = 0;
        let addr = "";

        let result = await axios.get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${originlng},${originlat};${lng},${lat}?alternatives=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=pk.eyJ1Ijoib20tZGF0YSIsImEiOiJjbGY2cXF3YXQwbmJoM3NubnRhYW5mbXpwIn0.qeCkkjp_WXmXrekPzF7wrQ`
        );
        if (result.status == 200) {
          if (result.data.routes && result.data.routes.length) {
            if (result.data.routes[0].distance) {
              let distanceBetweenTwoPoint = result.data.routes[0].distance;
              distance = Number((distanceBetweenTwoPoint / 1000).toFixed(2));
              addr = result.data.waypoints[1].name;

              let dist = {
                resLat: originlat,
                cusLat: lat,
                resLng: originlng,
                cusLng: lng,
                disRestToCus: distance
              };

              dispatch(distanceAction.saveDistance(dist));

              navigate("/products");
            }
          }
        }
      } catch (error) {
        // console.log(error, "error in calculating distance");
        return error;
      }
    }
  };

  const onResClickHandler = (res) => {
    let orderType = "Drive Out";
    localStorage.setItem("resId", res._id);
    localStorage.setItem("resName", res.name);
    localStorage.setItem("tdDriveOutDelay", res.tdDriveOutDelay);
    localStorage.setItem("tdTakeAwayDelay", res.tdTakeAwayDelay);
    if (root.orderType === "reserveTable") {
      const userIdnew = userId;
      window.location.href = `${reserveTableurl}${res.slug}?userId=${userIdnew}`;
      return;
    }

    if (res.isTreeDriveDriveOutAllowed == true && res.isRestDriveOut == true) {
      orderType = "Drive Out";
    }

    if (res.isTreeDrivePickUpAllowed == true && res.isRestPickup == true) {
      orderType = "Take Away";
    }

    dispatch(rootAction.setOrderType(orderType));

    localStorage.setItem(
      "resLoc",
      JSON.stringify({
        lat: res.resLatitude,
        lng: res.resLongitude
      })
    );
    dispatch(restaurantAction.saveSelectedRestaurant(res));
    setDistance(res.resLongitude, res.resLatitude);
  };
  return (
    <>
      {props.isLoading && (
        <div
          className="all-flex-center"
          style={{
            width: "100%",
            height: "100vh",
            color: "white"
          }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      )}
      {isMobile ? (
        <div className="cardList row" style={{ position: "relative" }}>
          {props.restaurant.length > 0 &&
            props.restaurant.map((val, ind) => {
              return (
                <ProductCard
                  key={val._id}
                  res={val}
                  onResClickHandler={onResClickHandler}
                />
              );
            })}
        </div>
      ) : (
        <div className="cardList px-4 row" style={{ position: "relative" }}>
          {props.restaurant.length > 0 &&
            props.restaurant.map((val, ind) => {
              return (
                <ProductCard
                  key={val._id}
                  res={val}
                  onResClickHandler={onResClickHandler}
                />
              );
            })}
        </div>
      )}
    </>
  );
};
export default Cards;
