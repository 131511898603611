import OrderedProducts from "./OrderProducts";
import TotalTaxCal from "./TotalTaxCal";
import CartOrderButton from "./CartOrderButton";
import { useSelector } from "react-redux";

import { Trans } from "react-i18next";
import i18n from "./../../i18n";

const ConfirmOrder = (props) => {
  let cart = useSelector((state) => state.cartReducer);

  return (
    <>
      <div className="bill-body">
        <div
          className="billModalProductList p-2 border-radius-10 spreadedShadow"
          style={{ height: "40vh", overflowY: "scroll" }}
        >
          <OrderedProducts
            cart={cart.cartItems && cart.cartItems.length ? cart.cartItems : []}
          />
        </div>
        <TotalTaxCal cart={cart} />
      </div>
      <CartOrderButton handleCancel={props.handleCancel} />
    </>
  );
};
export default ConfirmOrder;
