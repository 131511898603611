import { Empty } from "antd";
import React, { Component } from "react";
import searchGif from "../../assets/search.gif";
import BottomFooter from "../Footer/BottomFooter";
// Import for Language translater
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const App = () => {
  //For Language Translater
  const { t, i18n } = useTranslation(); // Hook to access translations

  return (
    <>
      {" "}
      <Empty
        className="searchagain-img d-flex flex-column mb-5 justify-content-center align-items-center"
        image={searchGif}
        imageStyle={{
          height: 200
        }}
        description={
          <>
            {" "}
            <span className="font-24" style={{ lineHeight: "25px" }}>
              <Trans i18nKey="RestaurantFind" components={{ br: <br /> }} />
            </span>
          </>
        }
      />
      <BottomFooter />
    </>
  );
};
export default App;
